import React, { Component } from 'react';
import {t} from '../components/ServiceLang';


//// timezone
/*
function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

// usage: Asia/Jakarta is GMT+7
convertTZ("2012/04/10 10:10:30 +0000", "Asia/Jakarta") // Tue Apr 10 2012 17:10:30 GMT+0700 (Western Indonesia Time)

// Resulting value is regular Date() object
const convertedDate = convertTZ("2012/04/10 10:10:30 +0000", "Asia/Jakarta") 
convertedDate.getHours(); // 17

// Bonus: You can also put Date object to first arg
const date = new Date()
convertTZ(date, "Asia/Jakarta") // current date-time in jakarta.
*/
Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

function getCurrentTime(){
        var d = new Date();
        var h = d.getHours();
        let m = d.getMinutes();
        let h2 = new Date().addHours(3).getHours();
        let h3 = new Date().addHours(2).getHours();

        return {h, m, h2, h3}
    }

var interval = null;
class Localtime extends Component {

    constructor(props){
        super(props);
        this.state = {
          time: '',
          time2: '',
          time3: ''
        }
    }

    componentDidMount(){
        interval = setInterval(()=>{
            let t = getCurrentTime();
            if(t.m <= 9){
                t.m = "0" + t.m;
            }
            if(t.h <= 9){
                t.h = "0" + t.h;
            }
            if(t.h2 <= 9){
                t.h2 = "0" + t.h2;
            }
            if(t.h3 <= 9){
                t.h3 = "0" + t.h3;
            }
            this.setState({time: t.h +':'+ t.m, time2: t.h2 +':'+ t.m, time3: t.h3 +':'+ t.m});
        }, 3000);
    }

    render(){
        return(
           <div className="container-padding localtime">
                <div className="home-localtime">
                    {/* vreme po gradovima */}
                    <div className="localtime-item">
                        <div className="circle">
                            <p className="time">{this.state.time}</p>
                        </div>
                        <p>{t("BELGRADE")}</p>
                        <span>GMT +1</span>
                    </div>
                    <div className="localtime-item">
                        <div className="my-localtime">
                            <p className="time">{this.state.time}</p>
                        </div>
                        <p>{t("yourTime")}</p>
                        <span>GMT +1</span>
                    </div>
                    <div className="localtime-item">
                        <div className="circle">
                            <p className="time">{this.state.time2}</p>
                        </div>
                        <p>{t("MOSCOW")}</p>
                        <span>GMT +3</span>
                    </div>
                    <div className="localtime-item">
                        <div className="circle">
                            <p className="time">{this.state.time3}</p>
                        </div>
                        <p>{t("BUCHAREST")}</p>
                        <span>GMT +2</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default Localtime;