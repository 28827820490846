import React, { Component } from 'react';
import Localtime from '../components/Localtime';
import renderHTML from 'react-render-html';
import Service from '../components/Service';
import {t} from '../components/ServiceLang';

let n = [{id: 0, date: '2021-11-22', title: "NEWS 1 TITLE", description: 'some description one', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: 'ok neki tekst'}]
        

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            title: '',
            content: '',
            image: '',
            date: ''
        }
    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.getNews();
        }
    }

    getNews=()=>{
        Service.get('/news/get',{
            id: this.props.match.params.id
        },(res)=>{
            if(res.success){
                this.setState({
                    title: res.news[0].title,
                    content: res.news[0].content,
                    image: res.news[0].image,
                    date: res.news[0].created_at,
                })
            }
         }); 
    }

    convertDate=(date)=>{
        let monthNames = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"),
            t("july"), t("august"), t("september"), t("october"), t("november"), t("december")
        ];

        let cDate = new Date(date);
        let year = cDate.getFullYear();
        let month = monthNames[cDate.getMonth()];
        let dt = cDate.getDate();
        
        if (dt < 10) {
          dt = '0' + dt;
        }
        // if (month < 10) {
        //   month = '0' + month;
        // }
        
        return dt+' ' + month + ' '+year;
    }

    render() { 
        return (
            <div className="content">
                <div className="single-news">
                    <div className="single-news-padding">
                        <div className="breadcrumbs">
                            <p>{t("news")}</p>
                            {this.state.date &&
                                <span>{this.convertDate(this.state.date)}</span>
                            }
                        </div>
                        
                        <h2 className="single-news-title">{this.state.title}</h2>
                    </div>
                        <img className="single-news-featured-image" src={Service.media + this.state.image} />
                    
                    <div className="single-news-padding">
                        {/* <div className="single-news-content">{renderHTML(this.state.content)}</div> */}
                        <div className="single-news-content" dangerouslySetInnerHTML={{__html: this.state.content}} />
                    </div> 
                </div>    
                <Localtime />
            </div>
        );
    }

}

export default News;