import React, { Component } from 'react';
import Localtime from '../components/Localtime';
import renderHTML from 'react-render-html';
import Service from '../components/Service';
import {t} from '../components/ServiceLang';

let n = [{id: 0, date: '2021-11-22', title: "NEWS 1 TITLE", description: 'some description one', image: 'https://cedarengineeringltd.com/wp-content/uploads/2020/01/petrol-station-CEdar.jpg', content: 'ok neki tekst'}]
        

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            title: '',
            content: '',
            image: '',
            date: '',
            description: ''
        }
    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.getProduct();
        }
    }

    getProduct=()=>{
        Service.get('/products/get',{
            id: this.props.match.params.id
        },(res)=>{
            if(res.success){
                this.setState({
                    title: res.products[0].title,
                    content: res.products[0].content,
                    image: res.products[0].image,
                    date: res.products[0].created_at,
                    description: res.products[0].description
                })
            }
        });
    }

    
        
    convertDate=(date)=>{
        let cDate = new Date(date);
        let year = cDate.getFullYear();
        let month = cDate.getMonth()+1;
        let dt = cDate.getDate();
        
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        
        return year+'-' + month + '-'+dt;
    }

    render() { 
        return (
            <div className="content">
                <div className="single-product">
                    <div className="single-product-padding single-product-top" style={{backgroundImage: `url(${Service.media + this.state.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div className="single-product-background-color">
                        <a href="#product-single-content"><button>{t("characteristics")}</button></a>

                        </div>

                        <div className="single-product-top-title">
                            {/* {this.state.date &&
                                <h5>{this.convertDate(this.state.date)}</h5>
                            } */}
                            <h2 className="single-product-title">{this.state.title}</h2>
                            <p className="single-product-description">{this.state.description}</p>
                        </div>
                      
                    </div>
                        {/* <img className="single-product-featured-image" src={Service.media + this.state.image} /> */}
                    
                    <div className="single-product-padding" id="product-single-content">
                        {/* <div className="single-product-content">{renderHTML(this.state.content)}</div> */}
                        <div className="single-product-content" dangerouslySetInnerHTML={{__html: this.state.content}} />

                    </div> 
                </div>    
                <Localtime />
            </div>
        );
    }

}

export default Products;