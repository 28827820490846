import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';

class AdminCareers extends Component {
    constructor(){
        super();
        this.state={
            positions: [],
            isCreate: false,
            title: '',
            description: '',
            image: '',
            lang: 'sr',
            listLang: 'sr',
            showMedia: false,
            showQuillMedia: false,
        }
    }

    componentDidMount(){
        this.getPositions();
    }

    isCreate=()=>{
        this.setState({isCreate: !this.state.isCreate})
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    createPosition=()=>{
        if(!window.confirm('Are you sure that you want to create new position?')){
            return;
        }

        Service.get('/adm/addPosition',{
            description: this.state.description,
            name: this.state.name,
            file: this.state.image,
        },(res)=>{
            if(res.success){
                this.setState({isCreate: false, description: '', name: '', image: ''},()=>{
                    this.getPositions();
                    NotificationManager.success("You have successfully create new position")
                })
            }
        }); 
    }

    getPositions=()=>{
        Service.get('/adm/getPositions',{
        },(res)=>{
            if(res.success){
                this.setState({positions: res.positions})
            }
        }); 
    }

    editPosition=(event)=>{
        let id = event.target.getAttribute('data-id');

        window.location.href= '/admin/career-single/' + id;
    }

    deletePoisiton=(event)=>{
        let id = event.target.getAttribute('data-id');

        if(!window.confirm('Are you sure that you want to delete this position?')){
            return;
        }
        
        Service.get('/adm/deletePosition',{
            positionId: id
        },(res)=>{
            if(res.success){
                this.getPositions();
                NotificationManager.success("You have successfully delete position")
            }
        }); 
    }

    showMedia=()=>{
        this.setState({showMedia: !this.state.showMedia})
    }

    setImage=(img)=>{
        this.setState({image: img, showMedia: false});
    }

    setLang=(event)=>{
        this.setState({lang: event.target.value})
    }

    setListLang=(event)=>{
        this.setState({listLang: event.target.value},()=>{
            this.getPositions();
        })
    }

    setPositionStatus=(e)=>{
        let positionId = e.target.getAttribute('data-id');

        Service.get("/adm/setPositionStatus",{
            positionId
        }, (res) => {
            if(res.success){
                this.getPositions();
            }
        });
    }

    render() {
        return (     
            <div>
                <h1>Careers</h1>

                <button type="button" className="btn btn-primary" onClick={this.isCreate}>Create position</button>

                {/* <div className="form-group">
                    <label htmlFor="list-language" className="label-select-language">Language</label>
                    <select id="list-language" value={this.state.listLang} onChange={this.setListLang}>
                        <option value="en" >English</option>
                        <option value="sr" >Serbian</option>
                    </select> 
                </div> */}

                {this.state.isCreate &&
                    <Fragment>
                        <hr />
                        <h2>Create position</h2>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea type="text" className="form-control" id="description" value={this.state.description} onChange={this.handleInput}/>
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="language" className="label-select-language">Language</label>
                            <select id="language" value={this.state.lang} onChange={this.setLang}>
                                <option value="en" >English</option>
                                <option value="sr" >Serbian</option>
                            </select> 
                        </div> */}

                        {this.state.showQuillMedia === true &&
                            <div className="admin-media-popup">
                                <p onClick={this.showQuillMedia}>X</p>
                                <AdminMedia onClick={this.setQuillImage} isFunction={true}/>
                            </div>
                        }

                        <div className="form-group">
                            <label htmlFor="images" className="label-select-image">Select file</label>
                            <button type="submit" id="images" className="btn btn-secondary" onClick={this.showMedia}>Files</button>
                            {this.state.image && 
                                // <img className="preview-selected-image" src={Service.media + this.state.image} />
                                <>
                                    <p>{this.state.image}</p>
                                    <a href={Service.media + this.state.image} target='_blank' download>Preview</a>
                                </>
                            }
                        </div>

                        {this.state.showMedia &&
                            <div className="admin-media-popup">
                                <p onClick={this.showMedia}>X</p>
                                <AdminMedia onClick={this.setImage} isFunction={true}/>
                            </div>
                        }
                        
                        <button type="submit" className="btn btn-primary" onClick={this.createPosition}>Submit</button>
                    </Fragment>
                }

                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Status</th>
                            <th scope="col">Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.positions.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td scope="row">{item.id}</td>
                                    <td><input type="checkbox" checked={item.status} data-id={item.id} onChange={this.setPositionStatus}/></td>
                                    <td>{item.name}</td>
                                    <td>{item.title}</td>
                                    <td>
                                        <button type="button" className="btn btn-secondary" data-id={item.id} onClick={this.editPosition}>Edit</button>
                                        <button type="button" className="btn btn-danger" data-id={item.id} onClick={this.deletePoisiton}>Delete</button>
                                    </td>
                                </tr>
                            )
                        },this)}
                    </tbody>
                </table>
            </div>
        );
    }
};
export default AdminCareers;
