import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom';
import Localtime from '../components/Localtime';
import Service from '../components/Service';
import {t} from '../components/ServiceLang';
import ReCAPTCHA from "react-google-recaptcha";

class CareerApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positionName: '',
            positionDescription: '',
            name: '',
            email: '',
            message: '',
            file: '',
            formData: {},
            nameEmpty: 'none',
            emailEmpty: 'none',
            fileEmpty: 'none',
            successMessage: '',
            recaptchaError: 'none',
            googleRecaptcha: false,
        }
    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.getCareers();
        }
    }

    getCareers = () => {
        Service.get('/careers/get',{ 
            positionId: this.props.match.params.id
        },(res)=>{
           if(res.success){
               this.setState({
                   positionName: res.position.name,
                   positionDescription: res.position.description
               })
           }
        }); 
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    upload = (event) => {
        this.setState({formData: event.target.files});       
    }

    validateForm = () => {
        let check = true;

        if(Object.keys(this.state.formData).length > 0){
            this.setState({fileEmpty: 'none'})
        }else{
            this.setState({fileEmpty: 'block'})
            check = false;
        }

        if(this.state.name){
            this.setState({nameEmpty: 'none'})
        }else{
            this.setState({nameEmpty: 'block'})
            check = false;
        }

        if(this.state.email){
            this.setState({emailEmpty: 'none'})
        }else{
            this.setState({emailEmpty: 'block'})
            check = false;
        }

        if(this.state.googleRecaptcha){
            this.setState({recaptchaError: 'none'});
        }else{
            this.setState({recaptchaError: 'block'});
            check = false;
        }

        return check;
    }

    getTest=(e)=>{
        e.preventDefault();

        if(!this.validateForm()){
            return;
        }

        // form data
        const files = Array.from(this.state.formData)
        const formData = new FormData()
        files.forEach((file, i) => {
            formData.append(i, file)
        });
        formData.append('prefix', 'cv');


        // request
        var request = new XMLHttpRequest();
        request.open("POST", Service.api+'/media/upload');
        request.onload = function(e) {
            if(request.status === 200) {
                let res = JSON.parse(request.response)
                if(res.success){
                    Service.get('/careers/apply',{ 
                        positionId: this.props.match.params.id,
                        file: res.data,
                        email: this.state.email,
                        name: this.state.name,
                        message: this.state.message
                    },(response)=>{
                       if(response.success){
                           this.setState({
                               successMessage: `${t("applicationMessage")} ${this.state.positionName}`
                           })
                       }
                    });
                }
            }
        }.bind(this);
        request.send(formData);
    }

    setCaptchaValue = (value) => {
        if(value){
            this.setState({googleRecaptcha: true})
        }    
    }

    render() { 
        return (
            <div className="content">
                <div className="careers">
                    <div className="careers-top">
                        <div className='breadcrumbs'>
                            <p>Petrolsoft Ing</p>
                            <span>{t("application")}</span>
                        </div>
                        <h1>{this.state.positionName}</h1>
                        <p>{this.state.positionDescription}</p>
                        <hr />

                        <div className="form-group">
                            <label htmlFor="phone">{t("fullName")}<span className="mandatory-field">*</span></label>
                            <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                            <p className="error" style={{display: this.state.nameEmpty}}>{t("nameEmpty")}</p>
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Email<span className="mandatory-field">*</span></label>
                            <input type="email" className="form-control" id="email" value={this.state.email} onChange={this.handleInput}/>
                            <p className="error" style={{display: this.state.emailEmpty}}>{t("emailEmpty")}</p>
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">{t("message")}</label>
                            <textarea type="text" className="form-control" id="message" value={this.state.message} onChange={this.handleInput}/>
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="multi" className="send-file-label" id="send-file-button">{t("uploadCV")}<span className="mandatory-field">*</span></label>
                            <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple/>  
                            <p className="error" style={{display: this.state.fileEmpty}}>{t("fileEmpty")}</p>
                        </div>
                                            
                        {/* google ReCAPTCHA */}
                        <ReCAPTCHA
                            sitekey="6LcyfJwhAAAAAH33Peo9MedjG9QEyOPPll2F7PU1"
                            onChange={this.setCaptchaValue}
                        />
                        <p className="error" style={{display: this.state.recaptchaError}}>{t("noRecaptcha")}</p>

                        <div className="careers-button application" onClick={this.getTest}>Submit</div>

                        <h4 className="application-message">{this.state.successMessage}</h4>
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default CareerApplication;