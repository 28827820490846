import React, { Component, Fragment } from 'react';
import {t} from '../components/ServiceLang';
import Localtime from '../components/Localtime';
import Service from '../components/Service';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [
                {id: '0', country: t('serbia'), info:{name: 'Šturkomerc', address: 'Patrijarha Dimitrija 12i', city: '11090 Beograd', phone: '+381 11 715 2552', email: 'office@petrolsofting.com'}},
                {id: '1', country: t('bih'), representativeOffice:{name: 'PSI d.o.o Banja Luka', address: 'Kralja Petra I Karađorđevića 85', city: '78000 Banja Luka', phone: '+387 51 962 950', email: 'office.bih@petrolsofting.com'}, distributors: {name: 'GIA BH d.o.o.', address: 'Trešnje br. 1', city: '71210 Sarajevo', phone: '+387 33 775 467', email: 'office@gia.ba'}},
                {id: '2', country: t('croatia'), representativeOffice:{name: 'Petrolsoft Ing', address: 'Mošćenička 15', city: '10110 Zagreb, Hrvatska', phone: '+385 1 560 1100', email: 'office.hr@petrolsofting.com'}, distributors: {name: 'GiA Hrvatska', address: 'Trgovačka 8', city: '10000 Zagreb', phone: '+385 1 2409 157', fax: '+385 1 2409 158', email: 'info@gia.hr'}},               
                {id: '3', country: t('romania'), distributors: {name: 'Kapsch S.R.L. World Trade Center Bucharest', address: '10 Montreal Square', city: '011469 Bucharest', phone: '+40 21 4087373', fax: '+40 21 4087374', email: 'mihai.ivanus@kapsch.net'}},
                {id: '4', country: t('bulgaria'), representativeOffice:{name: 'PetrolSoft Ing', address: 'Hristo Botev 56', city: '6000 Stara Zagora, Bugarska', phone: '+359 42 611 911', email: 'izotserv@mail.bg', fax: '+359 42 611 922'}, distributors: {name: 'Izotservice', address: '56 Hristo Botev Street', city: '6000 Stara Zagora', phone: '+359 42 611 911', fax: '+359 42 611 922', email: 'izotserv@mail.bg'}},
                // {id: '5', country: t('russia'), distributors: {name: 'Konstantin Tedeev', title: 'Head of operations Russia and CIS', phone: '+7 903 797 08 70', email: 'konstantin.tedeev@petrolsofting.com'}},
                {id: '5', country: t('montenegro'), representativeOffice:{name: 'Šturkomerc', address: 'Glisnica bb', city: '84211 Pljevlja', phone: '+381 11 175 2552', email: 'office@petrolsofting.com'}},
            ],
            selectedCountry: '0',
            contacts: []
        }
    }

    componentDidMount(){
        this.getContact();
    }

    selectCountry = (id) => {
        this.setState({selectedCountry: id},()=>{
            this.getContact();
        })
    }

    getContact=()=>{
        Service.get('/contact/get',{
            id: this.state.selectedCountry
        },(res)=>{
            if(res.success){
                this.setState({contacts: res.contacts})
            }
        }); 
    }

    render() { 
        return (
            <div className="content">
                <div className="contact">
                    <div className="title-contact">
                        <div className="breadcrumbs">
                            <p>{t("contact")}</p>
                        </div>
                        <h1 className="page-title">Petrolsoft</h1>
                        <h1 className="page-title">{t("contacts")}</h1>
                    </div>

                    <div className="contact-countries">
                        <ul className="contact-countries-list">
                            {this.state.countries.map((item, index)=>{
                                return(
                                    <li onClick={()=>{this.selectCountry(item.id)}} className={item.id === this.state.selectedCountry ? 'contact-selected-country' : null}>{item.country}</li>
                                )
                            })}
                        </ul>
                    </div>

                    {/* <div className="contact-info-container">
                        {this.state.countries.map((item, index)=>{
                            return(
                                <Fragment>
                                    {(this.state.selectedCountry === item.id && this.state.selectedCountry === '0') && 
                                        <div className="contact-info-item">
                                            <h1>{item.info.name} <span style={{color: '#5dbae5'}}>-HQ</span></h1>
                                            <h1>Petrolsoft Ing</h1>
            
                                            <ul className="contact-info-list">
                                                <li>{t("informations")}</li>
                                                <li>{t("address")} <p>{item.info.address}</p></li>
                                                <li>{t("city")} <p>{item.info.city}</p></li>
                                                <li className="contact-phone">{t("phone")} <p>{item.info.phone}</p></li>
                                                <li className="contact-email">Email <a href={"mailto:" + item.info.email}>{item.info.email}</a></li>
                                            </ul>
                                        </div>
                                    }

                                    {(this.state.selectedCountry === item.id && this.state.selectedCountry !== '0' && item.representativeOffice) &&
                                        <div className="contact-info-item">
                                            <span>{t("representativeOffice")}</span>
                                            <h1>{item.representativeOffice.name}</h1>

                                            <ul className="contact-info-list">
                                                <li>{t("informations")}</li>
                                                <li>{t("address")} <p>{item.representativeOffice.address}</p></li>
                                                <li>{t("city")} <p>{item.representativeOffice.city}</p></li>
                                                <li className="contact-phone">{t("phone")} <p>{item.representativeOffice.phone}</p></li>
                                                {item.representativeOffice.fax &&
                                                    <li>Fax <p>{item.representativeOffice.fax}</p></li>
                                                }
                                                <li className="contact-email">Email <a href={"mailto:" + item.representativeOffice.email}>{item.representativeOffice.email}</a></li>
                                            </ul>
                                        </div>
                                    }

                                    {(this.state.selectedCountry === item.id && this.state.selectedCountry !== '0' && item.distributors) &&
                                        <div className="contact-info-item">
                                            <span>{t("distributors")}</span>
                                            <h1>{item.distributors.name}</h1>

                                            <ul className="contact-info-list">
                                                <li>{t("informations")}</li>
                                                {item.distributors.address &&
                                                    <li>{t("address")} <p>{item.distributors.address}</p></li>
                                                }
                                                {item.distributors.title &&
                                                    <li><p>{item.distributors.title}</p></li>
                                                }
                                                {item.distributors.city &&
                                                    <li>{t("city")} <p>{item.distributors.city}</p></li>
                                                }
                                                <li className="contact-phone">{t("phone")} <p>{item.distributors.phone}</p></li>
                                                {item.distributors.fax &&
                                                    <li>Fax <p>{item.distributors.fax}</p></li>
                                                }
                                                <li className="contact-email">Email <a href={"mailto:" + item.distributors.email}>{item.distributors.email}</a></li>
                                            </ul>
                                        </div>
                                    }
                                </Fragment>
                            )
                        })}
                    </div>
                </div> */}

                <div className="contact-info-container">
                    {this.state.contacts.map((item, index)=>{
                        return(
                            <div className="contact-info-item">
                                {item.category === 'distributor' ?
                                    <span>{t("distributors")}</span>
                                    :
                                    item.category === 'representativeOffice' ?
                                        <span>{t("representativeOffice")}</span>
                                    :
                                    item.category === 'serviceCompany' ?
                                        <span>{t("serviceCompany")}</span>
                                    :
                                    null
                                }
                                <h1>{item.name}</h1>

                                <ul className="contact-info-list">
                                    <li>{t("informations")}</li>
                                    {item.address &&
                                        <li>{t("address")} <p>{item.address}</p></li>
                                    }
                                    {item.title &&
                                        <li><p>{item.title}</p></li>
                                    }
                                    {item.city &&
                                        <li>{t("city")} <p>{item.city}</p></li>
                                    }
                                    {item.phone && 
                                        <li className="contact-phone">{t("phone")} <p>{item.phone}</p></li>
                                    }
                                    {item.fax &&
                                        <li>Fax <p>{item.fax}</p></li>
                                    }
                                    {item.email &&
                                        <li className="contact-email">Email <a href={"mailto:" + item.email}>{item.email}</a></li>
                                    }
                                </ul>
                            </div>
                        )
                        })}
                    </div>
                </div>

                <div className="contact-image">
                    <img src="/contact.jpg" />
                </div>
                <Localtime />
            </div>
        );
    }

}

export default Contact;