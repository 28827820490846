const AboutUsSr ={
        title: `<h1 className="page-title">Snaga<br/>prave<br/> podrške</h1>`,
        content: `
        <div className="about-us-top-content">
            <div className="about-us-top-content-left">
                <div>1991 – 2023</div>
                <p>
                    Petrolsoft Ing. uspešno posluje duže od
                    30 godina i zapošljava 65 stručnjaka
                    sa lokalnim i međunarodnim iskustvom.
                </p>
            </div>
    
            <div className="about-us-top-content-mid">
                <p>
                    Zahvaljujući ekspertskom poznavanju poslovnih procesa i
                    zahteva klijenata u naftnoj industriji u regionu, postali smo
                    tehnološki i tržišni lider u jugoistočnoj Evropi.
                </p>

                <p>
                    Naša osnovna delatnost su End-To-End IT poslovna rešenja u
                    oblasti maloprodaje, naftne industrije i zdravstva.
                </p>

                <p>
                    U okviru kompanije uspostavili smo snažne timove za
                    istraživanje i razvoj softvera i hardvera, implementaciju i
                    integraciju IT rešenja, konsalting i servisne usluge.
                </p>
            </div>

            <div className="about-us-top-content-right">
                <p>
                    Našim klijentima nudimo najnovija tehnološka rešenja,
                    platformu i gotova aplikativna rešenja:
                    POS, BOS, HOS, BI, ERP, CMS i Payment koja su modularna,
                    skalabilna i klijentski orijentisana.
                </p>

                <p>
                    Nudimo Desktop, WEB i mobilne aplikacije, Cloud rešenja,
                    SaaS (Software As A Service) i integraciju naših rešenja sa
                    već implementiranim rešenjima – dopunjavanje do „punog
                    paketa".
                </p>

                <p>
                    Naravno, uvek smo tu za naše klijente 24 x 7.
                </p>
            </div>
        </div>

        <div className="about-us-middle-content">
            <div className="about-us-middle-content-left">
                <div className="about-us-iso-logo">
                    <img src="/iso-logo.svg" />
                </div>

                <div className="about-us-certificates">
                    <div className="about-us-certificate-item">
                        <p className="p-underline">9001:2008</p>
                        <p>9000</p>
                    </div>

                    <div className="about-us-certificate-item">
                        <p className="p-underline">ISO 27001:2015</p>
                        <p className="p-underline">ISO 27001:2013</p>
                    </div>

                    <div className="about-us-certificate-item">
                        <p>ITIL</p>
                </div>
            </div>
        </div>

        <div className="about-us-middle-content-right">
            <div className="about-us-middle-flags">
                <img src="/aboutus-flags.svg" />
            </div>
        </div>
    </div>

    <div className="about-us-bottom-content">
        <div className="about-us-bottom-content-left">
            <div>1991 – 2023</div>
            <p>
                Naša kompanija shvatajući značaj, donela je odluku o implementaciji zahteva
                standarda ISO 9001:2008 i ISO 27001:2015 izvršila je sve neophodne pripeme,
                počela da primenjuje i da generiše sve zahteve navedenih standarda.
            </p>

            <p>
                Uspešna sertifikacija i izdavanje sertifikata potvrduju da primenjujemo i
                održavamo sve zahteve Standarda sistema menadžmenta kvalitetom
                ISO 9001:2008 i sve zahteve Standarda o zaštiti i bezbednosti informacija
                ISO 27001:2013.
            </p>

            <p>
                ITIL je postao de-facto standard na području IT Service Managementa, i naša
                kompanija ga uspešno implementira u svom poslovanju.
            </p>

            <span>
                * Sertifikaciju je sprovelo renomirano sertifikaciono telo DAS Certification Ltd Oyt,
                22A Church St, Rushden, Northamptonshire NN10 9YT, UK.
            </span>
        </div>

        <div className="about-us-bottom-content-right">
            <div className="about-us-bottom-content-right-left">
                <p>
                    ISO 9001--
                </p>

                <p>
                    Sistem menadžmenta kvalitetom je međunarodni standard koji sadrži
                    zahteve za sistem upravljanja kvalitetom u poslovnoj organizaciji koje
                    organizacija mora ispuniti da bi uskladila svoje poslovanje sa
                    međunarodno priznatim normama.
                </p>

                <p>
                    Sistem kvaliteta je upravljački sistem, kojim se dovodi do ostvarenja
                    postavljenih ciljeva u pogledu kvaliteta poslovanja i pružanja usluga. Ovaj
                    sistem čine organizaciona struktura, odgovornost subjekta u organizaciji,
                    procesi i resursi potrebni za upravljanje sistemom.
                </p>

                <p>
                    Serija standarda ISO 9000 predstavlja skup od tri pojedinačna, ali medusobno
                    povezana standarda.
                </p>
            </div>
        

            <div className="about-us-bottom-content-right-right">
                <p>
                    ISO 27001--
                </p>

                <p>
                    Međunarodni standard koji se odnosi na zaštitu i bezbednost informacija.
                </p>

                <p>
                    Standard podleže različitim područjima primene kao i za razlikovanje mogućih
                    procesa u organizaciji koji su povezani sa upravljanjem kontrole sigurnosti,
                    kao što su:
                </p>

                <p>
                    Politika sigurnosti, sigurnost organizacije, kontrola i klasifikacija
                    izvora, sigurnost osoblja, sigurnost materijalnih dobara i životne sredine,
                    operativno upravljanje i komunikacija, kontrola pristupa, razvoj i održavanje
                    raznih sistema i upravljanje kontinuitetom poslovanja.
                </p>
            </div>
        </div>
    </div>
    `,
}

const AboutUsEng = {
    title: `<h1 className="page-title">The power<br/>of true<br/> support</h1>`,
    content: `
        <div className="about-us-top-content">
            <div className="about-us-top-content-left">
                <div>1991 – 2023</div>
                <p>
                    Petrolsoft Ing has been operating successfully for more than 
                    25 years and employs 65 professionals with local and international experience.
                </p>
            </div>

            <div className="about-us-top-content-mid">
                <p>
                    Thanks to the know-how and expert knowledge of 
                    business processes and customer requirements in the 
                    oil industry in the region, we have soon become a 
                    technological and market leader in Southeast Europe.
                </p>

                <p>
                    Our main activities are the End-To-End IT solutions 
                    for wholesale and retail, healthcare and oil and gas industry.
                </p>

                <p>
                    Within the company, we have established strong teams for 
                    research and development of software and hardware, implementation 
                    and integration of IT solutions, consulting and maintenance services.
                </p>
            </div>
        
            <div className="about-us-top-content-right">
                <p>
                    We offer our clients the latest technological solutions, the latest platform, 
                    as well as ready application solutions: POS, BOS, HOS, BI, ERP, CMS and Payment, 
                    modular, scalable and client-oriented.
                </p>

                <p>
                    We offer Desktop, Web and mobile applications, Cloud solutions, SaaS (Software As A Service), 
                    as well as the solutions with already implemented solutions – thus topping up "the full package".
                </p>

                <p>
                    Of course, we are always there for our customers, 24/7 to be exact.
                </p>
            </div>
        </div>
        <div className="about-us-middle-content">
            <div className="about-us-middle-content-left">
                <div className="about-us-iso-logo">
                    <img src="/iso-logo.svg" />
                </div>

                <div className="about-us-certificates">
                    <div className="about-us-certificate-item">
                        <p className="p-underline">9001:2008</p>
                        <p>9000</p>
                    </div>

                    <div className="about-us-certificate-item">
                        <p className="p-underline">ISO 27001:2015</p>
                        <p className="p-underline">ISO 27001:2013</p>
                    </div>

                    <div className="about-us-certificate-item">
                        <p>ITIL</p>
                </div>
            </div>
        </div>

        <div className="about-us-middle-content-right">
            <div className="about-us-middle-flags">
                <img src="/aboutus-flags.svg" />
            </div>
        </div>
    </div>

    <div className="about-us-bottom-content">
        <div className="about-us-bottom-content-left">
            <div>1991 – 2023</div>
            <p>
                Our company has made a decision on the implementation of the 
                requirements of ISO 9001:2008 , and ISO 27001:2015 fulfilled all 
                the necessary preperations, began to apply and to generate all the requirements of those standards.
            </p>

            <p>
                Successful certification and issuance of the certificates confirming that we apply and maintain 
                all the requirements of the quality management system Standard ISO 9001:2008 , as well as all the 
                requirements of the Standards on the protection and information security ISO 27001:2013.
            </p>

            <p>
                ITIL has become the de-facto standard in the area of IT Service Management, and our company 
                successfully implements it in its business operations.
            </p>

            <span>
                * The certification was conducted conducted by renowned certification body DAS Certification Ltd 9yt, 
                22A Church Street, Rushden, Northamptonshire NN10 9YT, UK.
            </span>
        </div>

        <div className="about-us-bottom-content-right">
            <div className="about-us-bottom-content-right-left">
                <p>
                    ISO 9001--
                </p>

                <p>
                    Quality Management System is an international standard containing requirements for 
                    a quality management system in business organization that the organization must meet 
                    in order to align its operations with internationally recognized standards.
                </p>

                <p>
                    The quality system is a control system, which leads to the realization of 
                    the set goals in terms of quality operations and service delivery. This system 
                    consists of organizational structure, the operator's responsibilities in the organization, 
                    processes and resources required for system management.
                </p>

                <p>
                    The series of standards ISO 9000 is a set of three individual but interrelated standards.
                </p>
            </div>
        

            <div className="about-us-bottom-content-right-right">
                <p>
                    ISO 27001--
                </p>

                <p>
                    International standard relating to the protection and security of information.
                </p>

                <p>
                    Standard is subject to various areas of application as well as the possible 
                    differentiation processes in the organization that are associated with the 
                    management of security controls, such as:
                </p>

                <p>
                    Security policy, security organization, control and classification of sources, security personnel, security of material goods and the environment, operational management and communication , access control, development and maintenance of various systems and business continuity management.
                </p>
            </div>
        </div>
    </div>
    `,
}



export {AboutUsSr, AboutUsEng};