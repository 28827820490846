import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import {NotificationManager} from 'react-notifications';
import AdminMedia from '../admin/AdminMedia';

// const categories = [
//     {id: '1', name: 'Petroleum industry'},
//     {id: '2', name: 'Internal petrol stations and marinas'},
//     {id: '3', name: 'No-petroleum industry'}
// ]

class AdminReferenceSingle extends Component {
    constructor(){
        super();
        this.state={
            id: '',
            // category: '',
            name: '',
            link: '',
            image: '',
            showMedia: false,
        }
    }

    componentDidMount(){
        if(this.props.id){
            this.getReference();
        }
    }

    getReference=()=>{
        Service.get('/adm/getReference',{
            id: this.props.id
        },(res)=>{
            if(res.success){
                this.setState({
                    id: res.reference[0].id,
                    name: res.reference[0].name,
                    // category: res.reference[0].category,
                    link: res.reference[0].link,
                    image: res.reference[0].image,
                })
            }
        });
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    updateReference=()=>{
        if(!window.confirm('Are you sure that you want to update this reference?')){
            return;
        }

        Service.get('/adm/updateReference',{
            id: this.props.id,
            name: this.state.name,
            // category: this.state.category,
            link: this.state.link,
            image: this.state.image,
        },(res)=>{
            if(res.success){
                this.getReference();
                NotificationManager.success("You have successfully update reference")
            }
        }); 
    }

    // setCategory=(event)=>{
    //     this.setState({category: event.target.value})
    // }

    showMedia=()=>{
        this.setState({showMedia: !this.state.showMedia})
    }

    setImage=(img)=>{
        this.setState({image: img, showMedia: false});
    }

    render() {
        return (     
            <div>
                <h1>{this.state.name}</h1>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                </div>

                {/* <div className="form-group">
                    <label htmlFor="category" className="label-select-language">Category</label>
                    <select id="category" value={this.state.category} onChange={this.setCategory}>
                        {categories.map(category =>{
                            return(
                                <option value={category.id}>{category.name}</option>
                            )
                        })}
                    </select> 
                </div> */}

                <div className="form-group">
                    <label htmlFor="phone">Link</label>
                    <input type="text" className="form-control" id="link" value={this.state.link} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="images" className="label-select-image">Select image</label>
                    <button type="submit" id="images" className="btn btn-secondary" onClick={this.showMedia}>Images</button>
                    {this.state.image && 
                        <img className="preview-selected-image" src={Service.media + this.state.image} />
                    }
                </div>

                {this.state.showMedia &&
                    <div className="admin-media-popup">
                        <p onClick={this.showMedia}>X</p>
                        <AdminMedia onClick={this.setImage} isFunction={true}/>
                    </div>
                }
                
                <button type="submit" className="btn btn-primary" onClick={this.updateReference}>Submit</button>
            </div>
        );
    }
};
export default AdminReferenceSingle;
