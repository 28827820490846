import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';
import { ThemeProvider } from 'react-bootstrap';
import { ReactComponent as UpArrow } from '../icons/up-arrow-svgrepo-com.svg';
import { ReactComponent as DownArrow } from '../icons/down-arrow-svgrepo-com.svg';
// const categories = [
//     {id: '1', name: 'Petroleum industry'},
//     {id: '2', name: 'Internal petrol stations and marinas'},
//     {id: '3', name: 'No-petroleum industry'}
// ]

class AdminReference extends Component {
    constructor(){
        super();
        this.state={
            reference: [],
            isCreate: false,
            // category: '1',
            // selectedCategory: '1',
            name: '',
            link: '',
            image: '',
            showMedia: false,
        }
    }

    componentDidMount(){
        this.getReference();
    }

    showMedia=()=>{
        this.setState({showMedia: !this.state.showMedia})
    }

    isCreate=()=>{
        this.setState({isCreate: !this.state.isCreate})
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    setImage=(img)=>{
        this.setState({image: img, showMedia: false});
    }

    createReference=()=>{
        if(!window.confirm('Are you sure that you want to create this reference?')){
            return;
        }

        Service.get('/adm/addReference',{
            name: this.state.name,
            // category: this.state.category,
            link: this.state.link,
            image: this.state.image,
        },(res)=>{
            if(res.success){
                this.setState({isCreate: false, name: '', link: '', image: ''},()=>{
                    this.getReference();
                    NotificationManager.success("You have successfully create reference")
                })
            }
        }); 
    }

    getReference=()=>{
        Service.get('/adm/getReference',{
            // category: this.state.selectedCategory
        },(res)=>{
            if(res.success){
                this.setState({reference: res.reference})
            }
        }); 
    }

    editReference=(event)=>{
        let id = event.target.getAttribute('data-id');

        window.location.href= '/admin/reference-single/' + id;
    }

    deleteReference=(event)=>{
        let id = event.target.getAttribute('data-id');

        if(!window.confirm('Are you sure that you want to delete this reference?')){
            return;
        }
        Service.get('/adm/deleteReference',{
            id: id
        },(res)=>{
            if(res.success){
                this.getReference();
                NotificationManager.success("You have successfully delete reference")
            }
        }); 
    }

    setItemPosition=(direction, referenceId)=>{
        Service.get('/adm/moveReference',{
            referenceId,
            direction
        },(res)=>{
            if(res.success){
                this.getReference();
            }
        });
    }

    // setCategory=(event)=>{
    //     this.setState({category: event.target.value})
    // }

    // setSelectedCategory=(event)=>{
    //     this.setState({selectedCategory: event.target.value},()=>{
    //         this.getReference();
    //     })
    // }

    render() {
        return (     
            <div>
                <h1>Reference</h1>
                <button type="button" className="btn btn-primary" onClick={this.isCreate}>Create reference</button>

                {/* <div className="form-group">
                    <label htmlFor="list-reference" className="label-select-language">Category</label>
                    <select id="list-reference" value={this.state.selectedCategory} onChange={this.setSelectedCategory}>
                        {categories.map(category =>{
                            return(
                                <option value={category.id}>{category.name}</option>
                            )
                        })}
                    </select> 
                </div> */}

                {this.state.isCreate &&
                    <Fragment>
                        <hr />
                        <h2>Create reference</h2>

                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="category" className="label-select-language">Category</label>
                            <select id="category" value={this.state.category} onChange={this.setCategory}>
                                {categories.map(category =>{
                                    return(
                                        <option value={category.id}>{category.name}</option>
                                    )
                                })}
                            </select> 
                        </div> */}

                        <div className="form-group">
                            <label htmlFor="phone">Link</label>
                            <input type="text" className="form-control" id="link" value={this.state.link} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="images" className="label-select-image">Select image</label>
                            <button type="submit" id="images" className="btn btn-secondary" onClick={this.showMedia}>Images</button>
                            {this.state.image && 
                                <img className="preview-selected-image" src={Service.media + this.state.image} />
                            }
                        </div>

                        {this.state.showMedia &&
                            <div className="admin-media-popup">
                                <p onClick={this.showMedia}>X</p>
                                <AdminMedia onClick={this.setImage} isFunction={true}/>
                            </div>
                        }
                      
                        <button type="submit" className="btn btn-primary" onClick={this.createReference}>Submit</button>
                    </Fragment>
                }

                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Positions</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {this.state.reference.map(function(item, index){
                            // let category = categories.find(o => o.id === item.category);
                            return(
                                <tr>
                                    <td scope="row" className="reference-table-logo">{item.image && <img src={Service.media + item.image} />}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        <UpArrow className="move-arrows up-arrow" onClick={()=>{this.setItemPosition(0, item.id)}} />
                                        <DownArrow className="move-arrows down-arrow" onClick={()=>{this.setItemPosition(1, item.id)}} /> 
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-secondary" data-id={item.id} onClick={this.editReference}>Edit</button>
                                        <button type="button" className="btn btn-danger" data-id={item.id} onClick={this.deleteReference}>Delete</button>
                                    </td>
                                </tr>
                            )
                        },this)}
                    </tbody>
                </table>
            </div>
        );
    }
};
export default AdminReference;
