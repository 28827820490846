import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';
// import { Document, Page } from 'react-pdf';
// import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'


class AdminApplicationSingle extends Component {
    constructor(){
        super();
        this.state={
            positions: [],
            id: '',
            name: '',
            email: '',
            message: '',
            file: '',
            positionId: '',
            positionName: '',        
            numPages: null,
            pageNumber: 1,
            status: ''
        }
    }
    
    componentDidMount(){
        if(this.props.id){
            this.getApplication();
            this.getPositions();
        }
    }

    getApplication=()=>{
        Service.get('/adm/getApplications',{
            applicationId: this.props.id
        },(res)=>{
            if(res.success){
                this.setState({
                    id: res.application.id,
                    name: res.application.name,
                    email: res.application.email,
                    message: res.application.message,
                    file: res.application.file,
                    positionId: res.application.position_id,
                    status: res.application.status
                })
            }
        });
    }

    getPositions=()=>{
        Service.get('/adm/getPositions',{},(res)=>{
            if(res.success){
                this.setState({
                    positions: res.positions
                })
            }
        });
    }

    getPositionName=(id)=>{
        let positionName = '';

        this.state.positions.map((item)=>{
            if(item.id === id){
                positionName = item.name
            }
        })
        return positionName;

    }

    onDocumentLoad=({ numPages })=>{
        this.setState({numPages})
    }

    setApplicationStatus=(e)=>{
        let applicationId = e.target.getAttribute('data-id');

        Service.get("/adm/setApplicationStatus",{
            applicationId,
            status: e.target.value
        }, (res) => {
            if(res.success){
                this.getApplication();
            }
        });
    }

    render() {
        return (     
            <div>
                <h1>Application</h1>
                <p>Position: {this.getPositionName(this.state.positionId)}</p>
                <p>Full name: {this.state.name}</p>
                <p>Email: {this.state.email}</p>
                <p>Message: {this.state.message}</p>
                <p>Status:
                    <select value={this.state.status} data-id={this.state.id} onChange={this.setApplicationStatus}>
                        <option value="0" >Pending</option>
                        <option value="1" >Seen</option>
                        <option value="2" >Approved</option>
                        <option value="3" >Rejected</option>
                    </select>
                </p>
                
                <a href={Service.media + this.state.file} target='_blank' download><button>Download CV</button></a>

                <Document
                    file={Service.media + this.state.file}
                    onLoadSuccess={this.onDocumentLoad}
                    >
                    <Page pageNumber={this.state.pageNumber} />
                </Document>
            </div>
        );
    }
};
export default AdminApplicationSingle;
