import React, { Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Auth} from '../components/Auth';

class HeaderAdmin extends Component {
    constructor(){
        super();
        this.state={
            
        }

    }

    render() {
        return (     
            <div className="admin-sidebar">
                <div className="admin-sidebar-content">
                    <ul>
                        <li className={this.props.location == 'news' ? 'admin-item-active': null}><Link to="/admin/news">News</Link></li>
                        <li className={this.props.location == 'products' ? 'admin-item-active': null}><Link to="/admin/products">Products</Link></li>
                        <li className={this.props.location == 'media' ? 'admin-item-active': null}><Link to="/admin/media">Media</Link></li>
                        <li className={this.props.location == 'contact' ? 'admin-item-active': null}><Link to="/admin/contact">Contact</Link></li>
                        <li className={this.props.location == 'reference' ? 'admin-item-active': null}><Link to="/admin/reference">Reference</Link></li>
                        <li className={this.props.location == 'employees' ? 'admin-item-active': null}><Link to="/admin/employees">Employees</Link></li>
                        <li className={this.props.location == 'careers' ? 'admin-item-active': null}><Link to="/admin/careers">Careers</Link></li>
                        <li className={this.props.location == 'applications' ? 'admin-item-active': null}><Link to="/admin/applications">Applications</Link></li>

                        <li className="admin-header-logout" onClick={()=>{Auth.signout()}}>Log Out</li>
                    </ul>
                </div>
            </div>
        );
    }
};
export default HeaderAdmin;
