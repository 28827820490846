import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link, Redirect, Read} from 'react-router-dom';
import HomeProducts from '../components/HomeProducts';
import Localtime from '../components/Localtime';
import {t} from '../components/ServiceLang';
import renderHTML from 'react-render-html';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="content">
                {/* Top content */}
                <div className="container-padding home-top">
                    <div className="home-top-content">
                        <div className="title">
                            <p>{t("homeSubtitle")}</p>
                            <h1>{t("homeTitle")}</h1>
                        </div>

                        {/* <div className="social-buttons">
                            <a href="https://www.facebook.com/petrolsofting/" target="_blank"><button>Facebook</button></a>
                            <a href="https://www.instagram.com/petrolsoft_ing/" target="_blank"><button>Instagram</button></a>
                        </div> */}

                        <div className="home-top-bottom">
                            <p>{t("homeText1")}</p>
                            {/* <p className="paragraph-inline">{renderHTML(t("homeText2"))}</p> */}
                            <p dangerouslySetInnerHTML={{__html: t("homeText2")}} />

                            <button>{t("homeLearnMoreButton")}</button>
                            <div className="line-container">
                            <div className="line"></div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <HomeProducts />

                {/* Bottom content */}
                <div className="home-bottom-content">
                    <div className="container-padding home-bottom-bottom">
                        <div className="list">
                            <ul>
                                <li>
                                    <Link to="/o-nama">Petrolsofting</Link>
                                </li>
                                <li>
                                    <Link to="/vesti">{t("news")}</Link>
                                </li>
                                <li>
                                    <Link to="/reference">{t("reference")}</Link>
                                </li>
                                {/* <li>
                                    <a href="#">{t("support")}</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>

                <Localtime />
            </div>
        );
    }

}

export default Home;