import {Auth} from '../components/Auth';

var api = 'https://ps.afirmadev.com/api';
var media = 'https://ps.afirmadev.com/api/files/';


const Service = {
    api: api,
    media: media,
    
    get(url, params, cb=null){
        params.Auth = Auth;

        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //headers: {'Content-Type':'application/x-www-form-urlencoded'},
            // Content-Type: text/html; charset=utf-8
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(result.error !== 'unauthorized request'){
                    if(cb){
                        if(result.message){
                            alert(result.message);
                        }
                        cb(result);
                    }
                }else{
                    Auth.signout();
                }
            },
            (error) => {
                cb(error);
                console.log('error', error);
            }
        )
    }

}

export default Service;