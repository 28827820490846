import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';

class AdminEmployeeSingle extends Component {
    constructor(){
        super();
        this.state={
            id: '',
            title: '',
            name: '',
            image: '',
            lang: '',
            showMedia: false,    
            description: '',
            descriptionEn: ''      
        }
    }
    
    componentDidMount(){
        if(this.props.id){
            this.getEmployee();
        }
    }

    getEmployee=()=>{
        Service.get('/adm/getEmployees',{
            employeeId: this.props.id
        },(res)=>{
            if(res.success){
                this.setState({
                    id: res.employee.id,
                    title: res.employee.title,
                    name: res.employee.name,
                    image: res.employee.image,
                    description: res.employee.description,
                    descriptionEn: res.employee.description_en
                })
            }
        });
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    updateEmployee=()=>{
        if(!window.confirm('Are you sure that you want to update this employee?')){
            return;
        }

        Service.get('/adm/updateEmployee',{
            employeeId: this.props.id,
            title: this.state.title,
            name: this.state.name,
            // lang: this.state.lang,
            image: this.state.image,
            description: this.state.description,
            descriptionEn: this.state.descriptionEn
        },(res)=>{
            if(res.success){
                this.getEmployee();
                NotificationManager.success("You have successfully update employee")
            }
        }); 
    }

    showMedia=()=>{
        this.setState({showMedia: !this.state.showMedia})
    }

    setImage=(img)=>{
        this.setState({image: img, showMedia: false});
    }

    
    setLang=(event)=>{
        this.setState({lang: event.target.value})
    }

    render() {
        return (     
            <div>
                <h1>{this.state.name}</h1>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea type="text" className="form-control" id="description" value={this.state.description} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="descriptionEn">Description English</label>
                    <textarea type="text" className="form-control" id="descriptionEn" value={this.state.descriptionEn} onChange={this.handleInput}/>
                </div>
              
                {/* <div className="form-group">
                    <label htmlFor="language" className="label-select-language">Language</label>
                    <select id="language" value={this.state.lang} onChange={this.setLang}>
                        <option value="en" >English</option>
                        <option value="sr" >Serbian</option>
                    </select> 
                </div> */}

                {this.state.showQuillMedia === true &&
                    <div className="admin-media-popup">
                        <p onClick={this.showQuillMedia}>X</p>
                        <AdminMedia onClick={this.setQuillImage} isFunction={true}/>
                    </div>
                }
                
                <div className="form-group">
                    <label htmlFor="images" className="label-select-image">Select image</label>
                    <button type="submit" id="images" className="btn btn-secondary" onClick={this.showMedia}>Images</button>
                    {this.state.image && 
                        <img className="preview-selected-image" src={Service.media + this.state.image} />
                    }
                </div>

                {this.state.showMedia &&
                    <div className="admin-media-popup">
                        <p onClick={this.showMedia}>X</p>
                        <AdminMedia onClick={this.setImage} isFunction={true}/>
                    </div>
                }

                <button type="submit" className="btn btn-primary" onClick={this.updateEmployee}>Submit</button>
            </div>
        );
    }
};
export default AdminEmployeeSingle;
