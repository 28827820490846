import React,{ Component, Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import axios from 'axios';

import {Auth} from './components/Auth';
import {t} from './components/ServiceLang';


import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import CookieConsent from "react-cookie-consent";

// Pages
import Home from './views/Home';
import News from './views/News';
import NewsSingle from './views/NewsSingle';
import AboutUs from './views/AboutUs';
import Reference from './views/Reference';
import Contact from './views/Contact';
import Products from './views/Products';
import ProductsSingle from './views/ProductsSingle';
import Support from './views/Support';
import Careers from './views/Careers';
import CareerApplication from './views/CareerApplication';

// Admin
import Admin from './admin/Admin';

// Components
import Header from './components/Header';
import Footer from './components/Footer';



// function getLang(){

//     languages = ['sr', 'en'];
//     console.log('pn', window.location.pathname.split('/'));

//     let routeLang = window.location.pathname.split('/')[1]
//     if(languages.includes(routeLang)){
//         localStorage.setItem('psofting_lang', routeLang);
//     }
//     let lang = localStorage.getItem('psofting_lang');
//     Auth.setLang(lang);
//     return lang;
// }
const checkLoginStatus = () => {
  if (localStorage.getItem('ps-token') && localStorage.getItem('ps-userId')) {
    Auth.isAuthenticated = true;
    Auth.token = localStorage.getItem('ps-token');
    Auth.userId = localStorage.getItem('ps-userId');
    Auth.email = localStorage.getItem('ps-email');
  }else {
      Auth.isAuthenticated = false;
  }
}

const checkLangByIp = () => {
  if (!localStorage.getItem('psofting_lang')) {
      axios.get('https://ipapi.co/json/').then((response) => {
        if(response.data.country === 'RS'){
          localStorage.setItem('psofting_lang', 'sr');
        }else{
          localStorage.setItem('psofting_lang', 'en');
        }
      }).catch((error) => {
          console.log(error);
          localStorage.setItem('psofting_lang', 'en');
      });
  }
}

checkLangByIp();
checkLoginStatus();

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     Auth.isAuthenticated === true
//       ? <Component {...props} />
//       : <Redirect to={{
//           pathname: '/admin',
//           state: { from: props.location }
//         }} />
//   )} />
// )

class App extends Component {
  componentDidMount(){
    checkLoginStatus();
  }

  render() {
    return (
      <div className="wrapper">
        <Router basename={`/${Auth.initLang()}`}>
          {!window.location.pathname.slice(0, 6).match("/admin") ?
            <Fragment>
              <Header history={this.props.history} ref={this.header}/>

              <Route exact path="/" component={Home} />
              <Route exact path="/vesti" component={News} />
              <Route exact path="/blog" component={News} />
              <Route exact path="/vest/:id/:slug?" component={NewsSingle} />
              <Route exact path="/news/:id/:slug?" component={NewsSingle} />
              <Route exact path="/o-nama" component={AboutUs} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/reference" component={Reference} />
              <Route exact path="/references" component={Reference} />
              <Route exact path="/kontakt" component={Contact} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/proizvodi" component={Products} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/proizvod/:id/:slug?" component={ProductsSingle} />
              <Route exact path="/product/:id/:slug?" component={ProductsSingle} />
              <Route exact path="/podrska" component={Support} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/karijera" component={Careers} />
              <Route exact path="/career" component={Careers} />
              <Route exact path="/possition-apply/:id" component={CareerApplication} />
              <Footer />
            </Fragment>
          :
            <div className="admin">
              <Route exact path="/admin/:controler?/:id?" component={Admin} />
            </div>
          }
          
          <NotificationContainer/>
          <CookieConsent
              location="bottom"
              buttonText= {t("accept")}
              cookieName="psCookie"
              style={{ background: "#5dbae5", fontSize: '1em', textAlign: 'center'}}
              buttonStyle={{fontSize: "16px", background:'#27556f', color: 'white'}}
              expires={150}
          >
            {t("cookies")}
              
          </CookieConsent>
        </Router>
      </div>
    );
  }
}

export default withRouter(props => <App {...props}/>);
