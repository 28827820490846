import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';


class AdminContacts extends Component {
    constructor(){
        super();
        this.state={
            contacts: [],
            isCreate: false,
            category: 'representativeOffice',
            countryId: '0',
            country: 'serbia',
            name: '',
            address: '',
            city: '',
            phone: '',
            fax: '',
            email: '',
            title: ''
        }
    }

    componentDidMount(){
        this.getContacts();
    }

    isCreate=()=>{
        this.setState({isCreate: !this.state.isCreate})
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    createContact=()=>{
        if(!window.confirm('Are you sure that you want to create new contact?')){
            return;
        }

        Service.get('/adm/addContact',{
            name: this.state.name,
            category: this.state.category,
            country: this.state.country,
            countryId: this.state.countryId,
            address: this.state.address,
            city: this.state.city,
            phone: this.state.phone,
            fax: this.state.fax,
            email: this.state.email,
            title: this.state.title
        },(res)=>{
            if(res.success){
                this.setState({isCreate: false, name: '', category: 'representativeOffice', country: 'serbia', countryId: '0', address: '', city: '', phone: '', fax: '', email: '', title: ''},()=>{
                    this.getContacts();
                    NotificationManager.success("You have successfully create contact")
                })
            }
        }); 
    }

    getContacts=()=>{
        Service.get('/adm/getContacts',{
        },(res)=>{
            if(res.success){
                this.setState({contacts: res.contacts})
            }
        }); 
    }

    editContact=(event)=>{
        let id = event.target.getAttribute('data-id');

        window.location.href= '/admin/contact-single/' + id;
    }

    deleteContact=(event)=>{
        let id = event.target.getAttribute('data-id');

        if(!window.confirm('Are you sure that you want to delete this contact?')){
            return;
        }
        Service.get('/adm/deleteContact',{
            id: id
        },(res)=>{
            if(res.success){
                this.getContacts();
                NotificationManager.success("You have successfully delete contact")
            }
        }); 
    }

    setCategory=(event)=>{
        this.setState({category: event.target.value})
    }

    setCountry=(event)=>{
        let index = event.nativeEvent.target.selectedIndex;
        let country = event.nativeEvent.target[index].text;

        this.setState({countryId: event.target.value, country})
    }

    render() {
        return (     
            <div>
                <h1>Contacts</h1>
                <button type="button" className="btn btn-primary" onClick={this.isCreate}>Create contact</button>

                {this.state.isCreate &&
                    <Fragment>
                        <hr />
                        <h2>Create contact</h2>

                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="category" className="label-select-language">Category</label>
                            <select id="category" value={this.state.category} onChange={this.setCategory}>
                                <option value="representativeOffice" >Representative Office</option>
                                <option value="distributor" >Distributor</option>
                                <option value="serviceCompany" >Service company</option>
                            </select> 
                        </div>

                        <div className="form-group">
                            <label htmlFor="country" className="label-select-language">Country</label>
                            <select id="country" value={this.state.countryId} onChange={this.setCountry}>
                                <option value="0">serbia</option>
                                <option value="1" >bih</option>
                                <option value="2" >croatia</option>
                                <option value="3" >romania</option>
                                <option value="4" >bulgaria</option>
                                {/* <option value="5" >russia</option> */}
                                <option value="5" >montenegro</option>
                            </select> 
                        </div>

                        <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input type="text" className="form-control" id="address" value={this.state.address} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input type="text" className="form-control" id="city" value={this.state.city} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input type="text" className="form-control" id="phone" value={this.state.phone} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="fax">Fax</label>
                            <input type="text" className="form-control" id="fax" value={this.state.fax} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="text" className="form-control" id="email" value={this.state.email} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handleInput}/>
                        </div>
                        
                        <button type="submit" className="btn btn-primary" onClick={this.createContact}>Submit</button>
                    </Fragment>
                }

                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Country</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.contacts.map(function(item, index){
                            return(
                                <tr>
                                    <td scope="row">{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.category}</td>
                                    <td>{item.country}</td>
                                    <td>
                                        <button type="button" className="btn btn-secondary" data-id={item.id} onClick={this.editContact}>Edit</button>
                                        <button type="button" className="btn btn-danger" data-id={item.id} onClick={this.deleteContact}>Delete</button>
                                    </td>
                                </tr>
                            )
                        },this)}
                    </tbody>
                </table>
            </div>
        );
    }
};
export default AdminContacts;
