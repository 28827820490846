import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';

class AdminProductSingle extends Component {
    constructor(){
        super();
        this.state={
            id: '',
            title: '',
            description: '',
            content: '',
            image: '',
            lang: '',
            date: '',
            showMedia: false,
            showQuillMedia: false,
            modules: {
                clipboard: {
                    matchVisual: false,
                },
                toolbar: {
                    container: [
                        [{ 'header': [false, 2, 3, 4, 5] }],
                        [{ 'align': [] }],
                        ['bold', 'italic', 'underline',],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'script': 'super' }, 'strike'],
                        [{ 'color': ['#7f7f7f', '#00546e']}, { 'background': [] }], 
                        ['customImage'],
                        ['customHr']
                    ],
                    handlers: {
                        'customImage': ()=>{this.showQuillMedia()},
                        'customHr': ()=>{this.setCustomHr()},                        
                    }
                }
            }
        }

        
        this.handleContent = this.handleContent.bind(this)
        this.setQuillImage = this.setQuillImage.bind(this)
        this.setCustomHr = this.setCustomHr.bind(this)


        this.formats = [
            'header',
            'align',
            'bold', 'italic', 'underline', 
            'list', 'bullet',
            'indent', 'indent',
            'script', 'strike',
            'color', 'background',
            'customImage', 'image',
            'customHr'
        ];

        this.quillRef = null;
    }

    componentDidMount(){
        if(this.props.id){
            this.getProduct();
        }
    }

    getProduct=()=>{
        Service.get('/adm/getProducts',{
            id: this.props.id
        },(res)=>{
            if(res.success){
                this.setState({
                    id: res.products[0].id,
                    title: res.products[0].title,
                    description: res.products[0].description,
                    lang: res.products[0].lang,
                    content: res.products[0].content,
                    image: res.products[0].image,
                    date: res.products[0].created_at
                })
            }
        });
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    updateProduct=()=>{
        if(!window.confirm('Are you sure that you want to update this product?')){
            return;
        }

        Service.get('/adm/updateProduct',{
            id: this.props.id,
            title: this.state.title,
            description: this.state.description,
            lang: this.state.lang,
            content: this.state.content,
            image: this.state.image,
            date: this.state.date
        },(res)=>{
            if(res.success){
                this.getProduct();
                NotificationManager.success("You have successfully update product")
            }
        }); 
    }

    showMedia=()=>{
        this.setState({showMedia: !this.state.showMedia})
    }

    setImage=(img)=>{
        this.setState({image: img, showMedia: false});
    }

    handleContent=(value,delta, source)=>{
        if (source == 'user' && value != this.props.body) {
            this.setState({ content: value },()=>{
            })
        }
    }

    showQuillMedia=()=>{
        this.setState({showQuillMedia: !this.state.showQuillMedia})
    }

    setQuillImage=(imageName)=>{
        const editor = this.quillRef.getEditor();
        const cursorPosition = editor.getSelection().index;

   
        this.setState({showQuillMedia: false},()=>{
            editor.insertEmbed(cursorPosition, 'image', Service.media + imageName, ReactQuill.Quill.sources.USER);
            editor.setSelection(cursorPosition + 1);
        })
        
    }

    setCustomHr=()=>{
        const editor = this.quillRef.getEditor();
        const cursorPosition = editor.getSelection().index;

        editor.insertText(cursorPosition, "★");
        editor.setSelection(cursorPosition + 1);
        console.log("kristina", this.state.content)
    }

    setLang=(event)=>{
        this.setState({lang: event.target.value})
    }

    render() {
        var icons = ReactQuill.Quill.import('ui/icons');
        icons['customImage'] = '<i class="fa fa-picture-o" aria-hidden="true"></i>';
        return (     
            <div>
                <h1>{this.state.title}</h1>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <input type="text" className="form-control" id="description" value={this.state.description} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label>Content</label>
                    <ReactQuill 
                        ref={(el) =>{this.quillRef = el}}
                        onChange={this.handleContent}  
                        modules={this.state.modules}
                        selection={{start:0, end:0}}
                        formats={this.formats} 
                        value={this.state.content}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="date">Date</label>
                    <input type="text" className="form-control" id="date" value={this.state.date} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="language" className="label-select-language">Language</label>
                    <select id="language" value={this.state.lang} onChange={this.setLang}>
                        <option value="en" >English</option>
                        <option value="sr" >Serbian</option>
                    </select> 
                </div>

                {this.state.showQuillMedia === true &&
                    <div className="admin-media-popup">
                        <p onClick={this.showQuillMedia}>X</p>
                        <AdminMedia onClick={this.setQuillImage} isFunction={true}/>
                    </div>
                }
                
                <div className="form-group">
                    <label htmlFor="images" className="label-select-image">Select image</label>
                    <button type="submit" id="images" className="btn btn-secondary" onClick={this.showMedia}>Images</button>
                    {this.state.image && 
                        <img className="preview-selected-image" src={Service.media + this.state.image} />
                    }
                </div>

                {this.state.showMedia &&
                    <div className="admin-media-popup">
                        <p onClick={this.showMedia}>X</p>
                        <AdminMedia onClick={this.setImage} isFunction={true}/>
                    </div>
                }

                <button type="submit" className="btn btn-primary" onClick={this.updateProduct}>Submit</button>
            </div>
        );
    }
};
export default AdminProductSingle;
