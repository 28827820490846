import React, { Component, Fragment } from 'react';
import ReferencePartnersImages from '../components/ReferencePartnersJson';
import Localtime from '../components/Localtime';
import Service from '../components/Service';


const categories = [
    {id: '1', name: 'NAFTNA INDUSTRIJA'},
    {id: '2', name: 'Interne benzinske stanice i Marine'},
    {id: '3', name: 'NE—NAFTNA INDUSTRIJA'}
]

class Reference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerId: '',
            reference: []
        }
    }

    componentDidMount(){
        this.getReference();
    }

    getReference=()=>{
        Service.get('/reference/get',{
        
        },(res)=>{
            if(res.success){
                this.setState({reference: res.reference})
            }
        }); 
    }

    showPartnerLogo=(image, id)=>{
        this.setState({logo: image, partnerId: id})
    }

    windowPartnerLocation=(link)=>{
        window.open(link, '_blank');
    }

    render() { 
        return (
            <div className="content">
                <div className="reference">
                    <div className="reference-background-image">
                        <img src="/30-timmons-ny.svg"/>
                    </div>

                    <div className="reference-top">
                        <h4>Reference</h4>
                        <h1>Reference<br/>i partneri</h1>
                        <h4>1991-2021</h4>
                       
                        <div className="reference-text">
                            <div className="reference-text-left">
                                <p>
                                    Ponosni smo na listu naših klijenata,<br /> partnera i saradnika koju smo širili<br /> 
                                    tokom tri decenije našeg prisustva<br /> u širokom spektru država i tržišta.
                                </p>
                            </div>
                            
                            <div className="reference-text-right">
                                <p>
                                    Petrolsoft Ing. uspešno posluje duže od<br /> 30 godina i zapošljava 65 stručnjaka<br /> 
                                    sa lokalnim i međunarodnim iskustvom.
                                </p>
                            </div>   
                        </div>
                    </div>

                    <div className="reference-partners">
                        {this.state.reference.map((item)=>{
                            return(
                                <>
                                    {item.image &&
                                        <div className="reference-partner">
                                            {item.link ?
                                                <a href={item.link} target="_blank">
                                                    <img src={Service.media + item.image} alt={item.name}/>
                                                </a>
                                                :
                                                <div className="reference-partner-logo">
                                                    <img src={Service.media + item.image} alt={item.name}/>
                                                </div>
                                            }
                                            
                                        </div>
                                    }
                                </>
                            )
                        },this)}
                        {/* {categories.map((category)=>{
                            return(
                                <Fragment>
                                  
                                            <div className="reference-partner">
                                            <h1>{category.name}</h1>
                                                <ul>
                                                {this.state.reference.map((item, index)=>{
                                                        if(category.id === item.category){
                                                        return(  
                                                            <li className={item.link || item.image ? 'underline' : null} onClick={item.link ? ()=>{this.windowPartnerLocation(item.link)} : null} onMouseOver={item.image ? ()=>{this.showPartnerLogo(item.image, item.id)} : null}>
                                                                {item.name}

                                                                {this.state.partnerId === item.id &&
                                                                    <div className="reference-partner-logo">
                                                                        <img src={Service.media + item.image} />
                                                                    </div>
                                                                }
                                                            </li>      

                                                        )}
                                                    },this)}  
                                                </ul>
                                            </div>
                                       
                                </Fragment>
                            )
                        },this)} */}
                    </div>
                </div>
                
                <div className="reference-image">
                    <img src="/reference.jpg" />
                </div>
                <Localtime />
            </div>
        );
    }

}

export default Reference;