import React, { Component } from 'react';
import HeaderAdmin from '../admin/HeaderAdmin';
import AdminNews from './AdminNews';
import AdminNewsSingle from './AdminNewsSingle';
import AdminProducts from './AdminProducts';
import AdminProductSingle from './AdminProductSingle';
import AdminMedia from './AdminMedia';
import AdminContacts from './AdminContacts';
import AdminContactSingle from './AdminContactSingle';
import AdminReference from './AdminReference';
import AdminReferenceSingle from './AdminReferenceSingle';
import AdminEmployees from './AdminEmployees';
import AdminEmployeeSingle from './AdminEmployeeSingle';
import AdminCareers from './AdminCareers';
import AdminCareerSingle from './AdminCareerSingle';
import AdminApplications from './AdminApplications';
import AdminApplicationSingle from './AdminApplicationSingle';

import Login from './Login';
import {Auth} from '../components/Auth';

import './admin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment } from 'react';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="admin-content">
                {Auth.isAuthenticated ?
                    <Fragment>
                        <HeaderAdmin location={this.props.match.params.controler}/>

                        {this.props.match.params.controler === 'news' &&
                            <AdminNews />
                        }

                        {this.props.match.params.controler === 'news-single' &&
                            <AdminNewsSingle id={this.props.match.params.id} />
                        }

                        {this.props.match.params.controler === 'products' &&
                            <AdminProducts />
                        }

                        {this.props.match.params.controler === 'product-single' &&
                            <AdminProductSingle id={this.props.match.params.id} />
                        }

                        {this.props.match.params.controler === 'media' &&
                            <AdminMedia />
                        }

                        {this.props.match.params.controler === 'contact' &&
                            <AdminContacts />
                        }

                        {this.props.match.params.controler === 'contact-single' &&
                            <AdminContactSingle id={this.props.match.params.id} />
                        }

                        {this.props.match.params.controler === 'reference' &&
                            <AdminReference />
                        }

                        {this.props.match.params.controler === 'reference-single' &&
                            <AdminReferenceSingle id={this.props.match.params.id} />
                        }

                        {this.props.match.params.controler === 'employees' &&
                            <AdminEmployees />
                        }

                        {this.props.match.params.controler === 'employee-single' &&
                            <AdminEmployeeSingle id={this.props.match.params.id} />
                        }

                        {this.props.match.params.controler === 'careers' &&
                            <AdminCareers />
                        }

                        {this.props.match.params.controler === 'career-single' &&
                            <AdminCareerSingle id={this.props.match.params.id} />
                        }

                        {this.props.match.params.controler === 'applications' &&
                            <AdminApplications />
                        }

                        {this.props.match.params.controler === 'application-single' &&
                            <AdminApplicationSingle id={this.props.match.params.id} />
                        }
                    </Fragment>
                    :
                    <Login />
                }
    
            </div>
        );
    }

}

export default Admin;