import React, { Component } from 'react';
import Localtime from '../components/Localtime';
import Service from '../components/Service';
import { t } from '../components/ServiceLang';
import renderHTML from 'react-render-html';
import { AboutUsSr, AboutUsEng } from '../components/AboutUsJson';
import { Auth } from '../components/Auth';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            lang: 'sr',
            content: '',
            title: '',
        }
    }

    componentDidMount() {
        this.getEmployees();

        if(Auth.getLang){
            if(Auth.getLang() === 'sr'){
                this.setState({content: AboutUsSr.content, title: AboutUsSr.title})
            }else if(Auth.getLang() === 'en'){
                this.setState({content: AboutUsEng.content, title: AboutUsEng.title})
            }
        }
    }

    getEmployees = () => {
        Service.get('/employees/get',{},(res)=>{
           if(res.success){
               this.setState({
                   employees: res.employees,
               })
           }
        });
    }

    render() { 
        return (
            <div className="content ">
                <div className="about-us" >
                    <div className="title-about-us">
                        <div className="breadcrumbs">
                            <p>Petrolsoft Ing</p>
                            <span>{t("aboutUs")}</span>
                        </div>
                        {renderHTML(this.state.title)}
                    </div>
                    <div className="about-us-number"><img src="/30.png" /></div>

                    {renderHTML(this.state.content)}

                    <h1 className="employee-title">{t("ourTeam")}</h1>
                    <div className="about-us-employees">
                        {this.state.employees.map((item)=>{
                            return(
                                <div className="about-us-employee" key={item.id}>
                                    <div className="employee-image">
                                        <img src={Service.media + item.image} />
                                    </div>
                                    <h4>{item.name}</h4>
                                    <p>{item.title}</p>
                                    {localStorage.getItem('psofting_lang')  === 'sr' ?
                                        <p>{item.description}</p>
                                        :
                                        <p>{item.description_en}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default AboutUs;