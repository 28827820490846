const Products = [
    {
        id: 1,
        title: 'Kompletno IT poslovno rešenje',
        subtitle: 'Automatizacija poslovanja',
        content: '<p>Kroz potpunu automatizaciju i optimizaciju poslovanja obezbedite jednostavno upravljanje kompanijom, smanjenje operatinih troškova poslovanja, povećanje prodaje i margine zarade.</p> <p>Obezbedite brzo i sveobuhvatno izveštavanje radi donošenja pravovremenih i kvalitetnih poslovnih odluka.</p>',
        image: '/HomeProducts/1_0.png',
        items: [{
            id: 1,
            title: 'POS & BOS',
            image: '/HomeProducts/1_1.png',
            text: 'Prodaja goriva i DAS, upravljanje maloprodajom i restoranima',
            link: ''
        },
        {
            id: 2,
            title: 'PROCESS CONTROLLER',
            image: '/HomeProducts/1_2.png',
            text: 'Automatski sistem upravljanja pumpnim automatima, sondama, totemima ...',
            link: ''
        },
        {
            id: 3,
            title: 'HEAD OFFICE SYSTEM',
            image: '/HomeProducts/1_3.png',
            text: 'Centralizovano upravljanje partnerima, objektima, zalihama, nabavkom, cenama i promocijama',
            link: ''
        },
        {
            id: 4,
            title: 'CMS, OPT, AVI',
            image: '/HomeProducts/1_4.png',
            text: 'Unapređenje poslovanja ponudom novih proizvoda i naprednih servisa, B2B & B2C',
            link: ''
        },
        {
            id: 5,
            title: 'ERP & BI',
            image: '/HomeProducts/1_5.png',
            text: 'Knjigovodstvo i finansije, veleprodaja, transport, napredno izveštavanje i analitika',
            link: ''
        }]
    },
    {
        id: 2,
        title: 'Benzinska stanica bez ljudske posade',
        subtitle: 'Savremeno poslovno rešenje',
        content: '<p>Smanjite troškove prodaje i povećajte promet.</p> <p>Produžite radno vreme vaše benzinske stanice i obezbedite bržu uslugu potrošačima.</p> <p>Ponudite nižu cenu goriva kod samousluživanja i smanjite bezbednosni rizik.</p> <p>Na internim benzinskim stanicama, uspostavite potpunu kontrolu potrošnje goriva.</p>',
        image: '/HomeProducts/2_0.png',
        items: [{
            id: 1,
            title: 'PAYMENT TERMINAL',
            image: '/HomeProducts/2_1.png',
            text: 'Plaćanje gotovinom, bankarskim i kompanijskim karticama, vaučerima',
            link: ''
        },
        {
            id: 2,
            title: 'PROCESS CONTROLLER',
            image: '/HomeProducts/2_2.png',
            text: 'Automatski sistem upravljanja pumpnim automatima, sondama i totemima',
            link: ''
        },
        {
            id: 3,
            title: 'BOS & HOS',
            image: '/HomeProducts/2_3.png',
            text: 'Centralizovano (udaljeno) upravljanje kompletnim poslovanjem automatske benzinske stanice',
            link: ''
        },
        {
            id: 4,
            title: 'OMS',
            image: '/HomeProducts/2_4.png',
            text: 'Automatizovano (udaljeno) nadgledanje funkcija i alarma benzinske stanice',
            link: ''
        },
        {
            id: 5,
            title: 'AVI & PAY WAY',
            image: '/HomeProducts/2_5.png',
            text: 'Plaćanje bezkontaktnim AVI prstenom ugrađenim na vozilu ili mobilnom aplikacijom',
            link: ''
        }]
    },
    {
        id: 3,
        title: 'Kompanijske i loyalty kartice',
        subtitle: 'Vaš posao u Vašim rukama',
        content: '<p>Upravljajte sami svojom prodajom i diktirajte uslove na tržištu.</p> <p>Formirajte cene, popuste i pravila za svakog klijenta ponaosob uz dodatne pogodnosti i servise.</p> <p>Ponudite potrošačima novi način prodaje i marketinga.</p> <p>Mi smo tu da Vam pomognemo da privučete nove kupce i da potrošači postanu lojalni baš vama. Upoznajte naša rešenja.</p>',
        image: '/HomeProducts/3_0.png',
        items: [{
            id: 1,
            title: 'IZDAVANJE KARTICA, PIN',
            image: '/HomeProducts/3_1.png',
            text: 'Personalizovane kartice sa čipom, magnetnim tragom, bezkontaktne, PIN kod',
            link: ''
        },
        {
            id: 2,
            title: 'UPRAVLJANJE KARTICAMA',
            image: '/HomeProducts/3_2.png',
            text: 'Različiti tipovi kartica: kreditne, debitne, popust (keš), loyalty, itd.',
            link: ''
        },
        {
            id: 3,
            title: 'LIMITI I PRAVILA',
            image: '/HomeProducts/3_3.png',
            text: 'Upravljanje robnim limitima, finansijskim limitima i različitim cenovnim pravilima',
            link: ''
        },
        {
            id: 4,
            title: 'AVI & PAY WAY',
            image: '/HomeProducts/3_4.png',
            text: 'Autorizacija bezkontaktnim AVI prstenom ugrađenim na vozilu ili mobilnom aplikacijom',
            link: ''
        },
        {
            id: 5,
            title: 'ONLINE & CROSS ACCEPTANCE',
            image: '/HomeProducts/3_5.png',
            text: 'CMS je online transakcioni sistem sa cross countries i cross company servisima',
            link: ''
        },
        {
            id: 6,
            title: 'WEB I MOBILNA APLIKACIJA',
            image: '/HomeProducts/3_6.png',
            text: 'Preko WEB i Mobilne aplikacije CMS sistem je korisnicima dostupan u svakom trenutku i na svakom mestu',
            link: ''
        }]
    },
    {
        id: 4,
        title: 'Servisi',
        subtitle: 'Dopustite nam da brinemo o Vama i Vašem poslovanju',
        content: '<p>Brza usluga kreirana prema Vašim potrebama.</p> <p>Recite nam šta želite da unapredite u svom poslovanju i mi ćemo se pobrinuti da Vam se Vaše ideje ostvare.</p> <p>Preko 25 godina iskustva u malim, srednjim i velikim preduzećima, ekspertski timovi i najsavremenija tehnogija su tu 24 sata dnevno, 7 dana u nedelji u službi unapređenja vašeg poslovanja.</p>',
        image: '/HomeProducts/4_0.png',
        items: [{
            id: 1,
            title: 'JEDAN POZIV',
            image: '/HomeProducts/4_1.png',
            text: 'Integrisan service desk za sve IT sisteme i procesnu opremu',
            link: ''
        },
        {
            id: 2,
            title: 'BRZA REAKCIJA',
            image: '/HomeProducts/4_2.png',
            text: 'Brza reakcija na sve vaše zahteve',
            link: ''
        },
        {
            id: 3,
            title: 'PRILAGOĐENA USLUGA',
            image: '/HomeProducts/4_3.png',
            text: 'Usluga i servis prilagođeni vašim specifičnim poslovnim potrebama',
            link: ''
        },
        {
            id: 4,
            title: 'PRILAGOĐENO REŠENJE',
            image: '/HomeProducts/4_4.png',
            text: 'Prilagođavanje i izmena aplikacije prema vašim poslovnim zahtevima',
            link: ''
        },
        {
            id: 5,
            title: 'ISTRAŽIVANJE I RAZVOJ',
            image: '/HomeProducts/4_5.png',
            text: 'Kontinuirano unapređenje tehnologije i razvoj novih funkcionalnosti',
            link: ''
        },
        {
            id: 6,
            title: 'KONSALTING',
            image: '/HomeProducts/4_6.png',
            text: 'Naše široko ekspertsko znanje i iskustvo u službi vašeg poslovanja',
            link: ''
        }]
    }
]

const ProductsEng = [
    {
        id: 1,
        title: 'Ent-To-End IT business solution',
        subtitle: 'Business automation',
        content: '<p>Through complete automation and optimization of business, ensure simple company management, reduction of operative business expenses, increase in sales and the margin of earnings.</p> <p>Ensure fast and comprehensive reporting in the purpose of making timely and accurate business decisions.</p>',
        image: '/HomeProducts/1_0.png',
        items: [{
            id: 1,
            title: 'POS & BOS',
            image: '/HomeProducts/1_1.png',
            text: 'Fuel, Merchandise and food sale, managing retail and restaurants',
            link: ''
        },
        {
            id: 2,
            title: 'PROCESS CONTROLLER',
            image: '/HomeProducts/1_2.png',
            text: 'Automated system of control on pump dispensers, probes and totems',
            link: ''
        },
        {
            id: 3,
            title: 'HEAD OFFICE SYSTEM',
            image: '/HomeProducts/1_3.png',
            text: 'Centralized management of partners, petrol stations, supplies, acquisition, prices and promotions',
            link: ''
        },
        {
            id: 4,
            title: 'CMS, OPT, AVI',
            image: '/HomeProducts/1_4.png',
            text: 'Business improvement through offering new products and advanced services, B2B & B2C',
            link: ''
        },
        {
            id: 5,
            title: 'ERP & BI',
            image: '/HomeProducts/1_5.png',
            text: 'Accounting and finances, wholesale, transport, advanced reporting and analytics',
            link: ''
        }]
    },
    {
        id: 2,
        title: 'Unmanned petrol station',
        subtitle: 'Modern business solution',
        content: '<p>Reduce sales expenses and increase turnover.</p> <p>Extend working hours of your petrol station and ensure fast service to customers.</p> <p>Offer a lower price of fuel at self-service and reduce security risk.</p> <p>Establish full control of fuel consumption on the internal petrol stations.</p>',
        image: '/HomeProducts/2_0.png',
        items: [{
            id: 1,
            title: 'PAYMENT TERMINAL',
            image: '/HomeProducts/2_1.png',
            text: 'Payment in cash, banking and corporate cards, vouchers',
            link: ''
        },
        {
            id: 2,
            title: 'PROCESS CONTROLLER',
            image: '/HomeProducts/2_2.png',
            text: 'Automatic system for managing pump dispensers, probes and totems',
            link: ''
        },
        {
            id: 3,
            title: 'BOS & HOS',
            image: '/HomeProducts/2_3.png',
            text: 'Centralized (remote) management of all operations of automatic petrol station',
            link: ''
        },
        {
            id: 4,
            title: 'OMS',
            image: '/HomeProducts/2_4.png',
            text: 'Automated (remote) monitoring of functions and petrol station alarm',
            link: ''
        },
        {
            id: 5,
            title: 'AVI & PAY WAY',
            image: '/HomeProducts/2_5.png',
            text: 'Contactless payment through AVI ring mounted on a vehicle or a mobile application',
            link: ''
        }]
    },
    {
        id: 3,
        title: 'Company and loyalty cards',
        subtitle: 'Your business in your hands',
        content: '<p>Manage your own sales and dictate the market conditions.</p> <p>Form prices, discounts and rules with additional benefits and services for each client individually.</p> <p>Offer consumers a new way of selling and marketing.</p> <p>We are here to help you attract new customers and ensure that consumers become loyal just to you.</p> <p>Meet our solutions.</p>',
        image: '/HomeProducts/3_0.png',
        items: [{
            id: 1,
            title: 'ISSUING CARDS, PIN',
            image: '/HomeProducts/3_1.png',
            text: 'Personalized contactless cards with a chip, magnetic tracker, PIN codes',
            link: ''
        },
        {
            id: 2,
            title: 'CARD MANAGEMENT',
            image: '/HomeProducts/3_2.png',
            text: 'Different types of cards: credit, debit, discount (cash), loyalty, etc.',
            link: ''
        },
        {
            id: 3,
            title: 'LIMITS AND CONDITIONS',
            image: '/HomeProducts/3_3.png',
            text: 'Management of goods limits, financial limits and different pricing rules',
            link: ''
        },
        {
            id: 4,
            title: 'AVI & PAY WAY',
            image: '/HomeProducts/3_4.png',
            text: 'Authorization through contactless AVI ring mounted on a vehicle or with a mobile application',
            link: ''
        },
        {
            id: 5,
            title: 'ONLINE & CROSS ACCEPTANCE',
            image: '/HomeProducts/3_5.png',
            text: 'CMS is an online transaction system with cross countries and cross company services',
            link: ''
        },
        {
            id: 6,
            title: 'WEB AND MOBILE APPLICATIONS',
            image: '/HomeProducts/3_6.png',
            text: 'Through WEB and Mobile Application, CMS system is available to users at all times and in all places',
            link: ''
        }]
    },
    {
        id: 4,
        title: 'Services',
        subtitle: 'Let us take care of you and your business',
        content: '<p>Quick service created to suit your needs.</p> <p>Tell us what you want to improve in your business and we will make sure that you realize your ideas.</p> <p>Over 25 years of experience in small, medium and large enterprises, expert teams and the latest tehnology are there 24 hours a day, 7 days a week, in the service of improving your business.</p>',
        image: '/HomeProducts/4_0.png',
        items: [{
            id: 1,
            title: 'ONE CALL',
            image: '/HomeProducts/4_1.png',
            text: 'Integrated service desk for all IT systems and process equipment',
            link: ''
        },
        {
            id: 2,
            title: 'QUICK REACTION',
            image: '/HomeProducts/4_2.png',
            text: 'Quick response to all your requirements',
            link: ''
        },
        {
            id: 3,
            title: 'CUSTOM SERVICES',
            image: '/HomeProducts/4_3.png',
            text: 'Support and service tailored to your specific business needs',
            link: ''
        },
        {
            id: 4,
            title: 'CUSTOMISED SOLUTIONS',
            image: '/HomeProducts/4_4.png',
            text: 'Customization and modification of applications according to your business requirements',
            link: ''
        },
        {
            id: 5,
            title: 'RESEARCH & DEVELOPMENT',
            image: '/HomeProducts/4_5.png',
            text: 'Continuous improvement of technology and the development of new functionalities',
            link: ''
        },
        {
            id: 6,
            title: 'CONSULTING',
            image: '/HomeProducts/4_6.png',
            text: 'Our broad expertise and experience in the service of your business',
            link: ''
        }]
    }
]


export {Products, ProductsEng};