import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom';
import Localtime from '../components/Localtime';
import Service from '../components/Service';
import {t} from '../components/ServiceLang';

class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positions: []
        }
    }

    componentDidMount(){
        this.getCareers();
    }

    getCareers = () => {
        Service.get('/careers/get',{ 
        },(res)=>{
           if(res.success){
               this.setState({positions: res.positions})
           }
        }); 
    }

    render() { 
        return (
            <div className="content">
                <div className="careers">
                    <div className="careers-top">
                        <span>{t("career")}</span>
                        <h1>Petrolsoft<br/>{t("career")}</h1>
                    
                        <hr />

                        {this.state.positions.length > 0 ?
                            <ul className="careers-container">
                                {this.state.positions.map((item)=>{
                                    return(
                                        <li key={item.id}>
                                            <div className="career-title">
                                                <h2>{item.name}</h2>
                                                <p>{item.description}</p>
                                            </div>
                                            <div className="career-actions">
                                                <div className='careers-button'><a href={Service.media + item.file} target='_blank' download>details</a></div>
                                                <div className='careers-button'><Link to={`/possition-apply/${item.id}`}>apply</Link></div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            :
                            <p>{t("noOpenPositions")}</p>
                        }
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default Careers;