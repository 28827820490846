import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import {NotificationManager} from 'react-notifications';
import {Link} from 'react-router-dom';

class AdminMedia extends Component {
    constructor(){
        super();
        this.state={
            prefix: '',
            media: [],
            filter: '',
            selectedImage: ''
        }
    }

    componentDidMount(){
        this.getImages();
        // this.deleteImage();
    }

    upload = (event) => {
        const files = Array.from(event.target.files)
        this.setState({ uploading: true })
    
        const formData = new FormData()
    
        files.forEach((file, i) => {
            formData.append(i, file)
        })

        const updateProgress = (l, t) => {
            this.setState({uploaded: l, max: t})
        }

        formData.append('prefix', this.state.prefix);
    
        console.log(formData);

        var request = new XMLHttpRequest();
        request.open("POST", Service.api+'/adm/upload');
        //request.send(formData);
        // console.log('lngth',formData.getLengthSync());
        // request.onprogress = function (res) {
            //if (res.lengthComputable) {
            //}
        // }
        request.onload = function(e) {
            if (request.status === 200) {
                this.getImages();
            }
        }.bind(this);
        request.upload.addEventListener("progress", function(evt){
        if (evt.lengthComputable) {
            //this.setState({uploaded: evt.loaded, max: evt.total})
            updateProgress(evt.loaded, evt.total);
            console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
        }
        }, false);

        request.send(formData);
    }

    getImages=()=>{
        Service.get('/adm/getImages',{
            filter: this.state.filter
        },(res)=>{
            this.setState({media: res})
        });
    }

    handlePrefix=(event)=>{
        this.setState({prefix: event.target.value.replace(" ", "")});
    }

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            this.getImages();
        });
    }

    selectImage=(image)=>{
        this.setState({selectedImage: image},()=>{
            this.props.onClick(this.state.selectedImage);
        })
    }

    deleteImage=(image)=>{
        if(!window.confirm('Are you sure that you want to delete this image?')){
            return;
        }

        Service.get('/adm/deleteImage',{
            imageName: image
        },(res)=>{
            if(res.success){
                this.getImages();
                NotificationManager.success("You have successfully delete image");
            }
        });
    }

    render() {
        return (     
            <div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Prefix:</label>
                    <input type="text" className="form-control" id="prefix" value={this.state.prefix} onChange={this.handlePrefix}/>
                </div>
                <label htmlFor="multi" className="btn" id="send-file-button">+</label>
                <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple/>      

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Search:</label>
                    <input type="text" className="form-control" id="filter" value={this.state.filter} onChange={this.handleFilter}/>
                </div>

                {this.state.media.length > 0 &&
                    <div className="admin-media">
                        {this.state.media.map(function(item, index){
                            if(item !== 'default-image.png'){
                                return(
                                    <div className="admin-media-img">
                                        {item.substr(item.length - 3) === 'pdf' ?
                                            <>
                                                <p onClick={this.props.isFunction ? ()=>{this.selectImage(item)} : null}>{item}</p>
                                                <a href={Service.media + item} target='_blank' download>Download</a>
                                            </>
                                            :
                                            <img src={Service.media + item} onClick={this.props.isFunction ? ()=>{this.selectImage(item)} : null}/>
                                        }
                                        <button type="button" className="btn btn-primary" onClick={()=>this.deleteImage(item)}>Delete</button>
                                    </div>
                                )
                            }
                        },this)} 
                    </div>
                }         
            </div>
        );
    }
};
export default AdminMedia;
