import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom';
import Localtime from '../components/Localtime';
import Service from '../components/Service';
import {t} from '../components/ServiceLang';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            years: [],
            year: ''
        }
    }

    componentDidMount(){
        this.updateNews();
        this.getYears();
    }

    updateNews = () => {
        Service.get('/news/get',{
            year: this.state.selectedYear
        },(res)=>{
           if(res.success){
                this.setState({news: res.news},()=>{
                    window.scrollTo(0, 0)
                })
           }
        }); 
    }

    getYears = () => {
        Service.get('/news/getYears',{},(res)=>{
           if(res.success){
               this.setState({years: res.years})
           }
        }); 
    }

    selectYear = (year) => {
        this.setState({selectedYear: year},()=>{
            this.updateNews();
        })
    }

    selectAll = () => {
        this.setState({selectedYear: ''},()=>{
            this.updateNews();
        })
    }

    convertDate=(date)=>{
        let monthNames = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"),
            t("july"), t("august"), t("september"), t("october"), t("november"), t("december")
        ];

        let cDate = new Date(date);
        let year = cDate.getFullYear();
        let month = monthNames[cDate.getMonth()];
        let dt = cDate.getDate();
        
        if (dt < 10) {
          dt = '0' + dt;
        }
        // if (month < 10) {
        //   month = '0' + month;
        // }
        
        return dt+' ' + month + ' '+year;
    }
    
    render() { 
        return (
            <div className="content">
                <div className="news">
                    <div className="news-top">
                        <span>{t("news")}</span>
                        <h1>Petrolsoft<br/>{t("news2")}</h1>

                        <hr />

                        <ul className="news-container">
                            {this.state.news.map((item, index)=>{
                                return(
                                    <li>
                                        <img src={Service.media + item.image}></img>
                                        <div>
                                            <Link to={`/vest/${item.id}`}><h2>{item.title}</h2></Link>
                                            {/* <p>{item.description}</p> */}
                                            <p>{this.convertDate(item.created_at)}</p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="news-archive">
                        <h2>{t("archive")}</h2>
                        <ul className="news-archive-years">
                            <li onClick={()=>{this.selectAll()}} className={!this.state.selectedYear ? 'news-years-selected' : null}>{t("allNews")}</li>
                            {this.state.years.map((item, index)=>{
                                return(
                                    <li onClick={()=>{this.selectYear(item.year)}} className={item.year === this.state.selectedYear ? 'news-years-selected' : null}>{item.year}</li>
                                )
                            })}
                        </ul>
                        {/* <ul className="news-archive-months">
                            <li>januar</li>
                            <li>jun</li>
                            <li>septembar</li>
                        </ul> */}
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default News;