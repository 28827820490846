import React, { useState } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { useHistory } from "react-router";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const submitValue = () => {
        Auth.email = email;
        Auth.authenticate(password, () => {
            history.push({
                pathname:  "/admin",
            });
        }, ()=>{
            alert('incorrect access data');
        })
    }

    return(
        <div>
            <h1>Login</h1>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email</label>
                <input type="text" className="form-control" onChange={e => setEmail(e.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Password</label>
                <input type="password" className="form-control" onChange={e => setPassword(e.target.value)}/>
            </div>

            <button type="button" className="btn btn-primary" onClick={submitValue}>Submit</button>

        </div>
    )
}

export default Login;