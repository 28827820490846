import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom';
import Localtime from '../components/Localtime';
import Service from '../components/Service';
import {t} from '../components/ServiceLang';

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    }

    componentDidMount(){
        this.updateProducts();
    }

    updateProducts = () => {
        Service.get('/products/get',{ 
        },(res)=>{
           if(res.success){
               this.setState({products: res.products})
           }
        }); 
    }

    render() { 
        return (
            <div className="content">
                <div className="products">
                    <div className="products-top">
                        <span>{t("products")}</span>
                        <h1>Petrolsoft<br/>{t("products")}</h1>
                    
                        <hr />

                        <ul className="products-container">
                            {this.state.products.map((item, index)=>{
                                return(
                                    <li>
                                        <img src={Service.media + item.image}></img>
                                        <div>
                                            <Link to={`/proizvod/${item.id}`}><h2>{item.title}</h2></Link>
                                            <p>{item.description}</p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <Localtime />
            </div>
        );
    }

}

export default Products;