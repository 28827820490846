import React, { Component, Fragment } from 'react';
import {t} from '../components/ServiceLang';
import Localtime from '../components/Localtime';

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [
                // Serbia
                {id: '0', country: t('serbia'), support:{name: 'Petrolsofting doo', address: 'Patrijarha Dimitrija 12i', city: '11090 Beograd, Srbija', phone: '+381 11 715 2552', email: 'office@petrolsofting.com'}},
                // Bosnia
                {id: '1', country: t('bih'), support:{name: 'PSI d.o.o. Banja Luka', address: 'Kralja Petra I Karađorđevića 85', city: '78000 Banja Luka, BiH', phone: '+387 51 962 950', email: 'office@petrolsofting.com'}},
                // Croatia
                {id: '2', country: t('croatia'), support:{name: 'Petrolsoft Ing d.o.o. Zagreb', address: 'Nehajska ulica 2', city: '10110 Zagreb, Hrvatska', phone: '+385 01 5507 441', email: 'office.hr@petrolsofting.com'}},
                // Montenegro
                {id: '3', country: t('montenegro'), support:{name: 'Petrolsofting', address: 'Glisnica bb', city: '84211 Pljevlja, Crna Gora', phone: '+381 11 175 2552', email: 'office@petrolsofting.com'}},
                // Bulgaria
                {id: '4', country: t('bulgaria'), support:{name: 'TSG SOLUTION', address: 'Bul.Cvetan Lazarov 10A', city: '1582 Sofija, Bugarska', phone: '+359 2 9608100', email: 'service@tsg-solutions.bg'}},
                // Romania
                {id: '5', country: t('romania'), support: {name: 'Kapsch S.R.L. World Trade Center Bucharest', address: '10 Montreal Square', city: '011469 Bucharest', phone: '+40 21 4087373', fax: '+40 21 4087374', email: 'mihai.ivanus@kapsch.net'}},
            ],
            selectedCountry: '0'
        }
    }

    selectCountry = (id) => {
        this.setState({selectedCountry: id})
    }

    render() { 
        return (
            <div className="content">
                <div className="support">
                    <div className="title-support">
                        <div className="breadcrumbs">
                            <p>{t("support")}</p>
                        </div>
                        <h1 className="page-title">Petrolsoft</h1>
                        <h1 className="page-title">{t("support")}</h1>
                    </div>

                    <div className="support-countries">
                        <ul className="support-countries-list">
                            {this.state.countries.map((item, index)=>{
                                return(
                                    <li onClick={()=>{this.selectCountry(item.id)}} className={item.id === this.state.selectedCountry ? 'support-selected-country' : null}>{item.country}</li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className="support-info-container">
                        {this.state.countries.map((item, index)=>{
                            return(
                                <Fragment>
                                    {(this.state.selectedCountry === item.id && this.state.selectedCountry !== '6') && 
                                        <div className="support-info-item">
                                            <h1>{item.support.name}</h1>
            
                                            <ul className="support-info-list">
                                                <li className="support-info-list-informations">{t("informations")}</li>
                                                <li>{t("address")} <p>{item.support.address}</p></li>
                                                <li>{t("city")} <p>{item.support.city}</p></li>
                                                <li className="support-phone">{t("phone")} <p>{item.support.phone}  {item.support.phone2 ? item.support.phone2 : null}</p></li>
                                                {item.support.fax &&
                                                    <li>Fax <p>{item.support.fax}</p></li>
                                                }
                                                <li>Email <p><a href={"mailto:" + item.support.email}>{item.support.email}</a></p></li>
                                            </ul>
                                        </div>
                                    }   

                                </Fragment>
                            )
                        })}
                    </div>
                </div>

                <div className="contact-image">
                    <img src="/contact.jpg" />
                </div>
                <Localtime />
            </div>
        );
    }

}

export default Support;