import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import AdminMedia from '../admin/AdminMedia';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';

class AdminEmployees extends Component {
    constructor(){
        super();
        this.state={
            employees: [],
            isCreate: false,
            title: '',
            name: '',
            description: '',
            descriptionEn: '',
            image: '',
            lang: 'sr',
            listLang: 'sr',
            showMedia: false,
            showQuillMedia: false,
        }
    }

    componentDidMount(){
        this.getEmployees();
    }

    isCreate=()=>{
        this.setState({isCreate: !this.state.isCreate})
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    createEmployee=()=>{
        if(!window.confirm('Are you sure that you want to create new employee?')){
            return;
        }

        Service.get('/adm/addEmployee',{
            title: this.state.title,
            name: this.state.name,
            image: this.state.image,
            description: this.state.description,
            descriptionEn: this.state.descriptionEn
        },(res)=>{
            if(res.success){
                this.setState({isCreate: false, title: '', name: '', image: '', descriptionEn: '', description: ''},()=>{
                    this.getEmployees();
                    NotificationManager.success("You have successfully create new employee")
                })
            }
        }); 
    }

    getEmployees=()=>{
        Service.get('/adm/getEmployees',{
        },(res)=>{
            if(res.success){
                this.setState({employees: res.employees})
            }
        }); 
    }

    editEmployee=(event)=>{
        let id = event.target.getAttribute('data-id');

        window.location.href= '/admin/employee-single/' + id;
    }

    deleteEmployee=(event)=>{
        let id = event.target.getAttribute('data-id');

        if(!window.confirm('Are you sure that you want to delete this employee?')){
            return;
        }
        
        Service.get('/adm/deleteEmployee',{
            employeeId: id
        },(res)=>{
            if(res.success){
                this.getEmployees();
                NotificationManager.success("You have successfully delete employee")
            }
        }); 
    }

    showMedia=()=>{
        this.setState({showMedia: !this.state.showMedia})
    }

    setImage=(img)=>{
        this.setState({image: img, showMedia: false});
    }

    setLang=(event)=>{
        this.setState({lang: event.target.value})
    }

    setListLang=(event)=>{
        this.setState({listLang: event.target.value},()=>{
            this.getEmployees();
        })
    }

    setEmployeeStatus=(e)=>{
        let employeeId = e.target.getAttribute('data-id');

        Service.get("/adm/setEmployeeStatus",{
            employeeId
        }, (res) => {
            if(res.success){
                this.getEmployees();
            }
        });
    }

    render() {
        return (     
            <div>
                <h1>Employees</h1>

                <button type="button" className="btn btn-primary" onClick={this.isCreate}>Create employee</button>

                {/* <div className="form-group">
                    <label htmlFor="list-language" className="label-select-language">Language</label>
                    <select id="list-language" value={this.state.listLang} onChange={this.setListLang}>
                        <option value="en" >English</option>
                        <option value="sr" >Serbian</option>
                    </select> 
                </div> */}

                {this.state.isCreate &&
                    <Fragment>
                        <hr />
                        <h2>Create employee</h2>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea type="text" className="form-control" id="description" value={this.state.description} onChange={this.handleInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="descriptionEn">Description English</label>
                            <textarea type="text" className="form-control" id="descriptionEn" value={this.state.descriptionEn} onChange={this.handleInput}/>
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="language" className="label-select-language">Language</label>
                            <select id="language" value={this.state.lang} onChange={this.setLang}>
                                <option value="en" >English</option>
                                <option value="sr" >Serbian</option>
                            </select> 
                        </div> */}

                        {this.state.showQuillMedia === true &&
                            <div className="admin-media-popup">
                                <p onClick={this.showQuillMedia}>X</p>
                                <AdminMedia onClick={this.setQuillImage} isFunction={true}/>
                            </div>
                        }

                        <div className="form-group">
                            <label htmlFor="images" className="label-select-image">Select image</label>
                            <button type="submit" id="images" className="btn btn-secondary" onClick={this.showMedia}>Images</button>
                            {this.state.image && 
                                <img className="preview-selected-image" src={Service.media + this.state.image} />
                            }
                        </div>

                        {this.state.showMedia &&
                            <div className="admin-media-popup">
                                <p onClick={this.showMedia}>X</p>
                                <AdminMedia onClick={this.setImage} isFunction={true}/>
                            </div>
                        }
                        
                        <button type="submit" className="btn btn-primary" onClick={this.createEmployee}>Submit</button>
                    </Fragment>
                }

                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Status</th>
                            <th scope="col">Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.employees.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td scope="row">{item.id}</td>
                                    <td><input type="checkbox" checked={item.status} data-id={item.id} onChange={this.setEmployeeStatus}/></td>
                                    <td>{item.name}</td>
                                    <td>{item.title}</td>
                                    <td>
                                        <button type="button" className="btn btn-secondary" data-id={item.id} onClick={this.editEmployee}>Edit</button>
                                        <button type="button" className="btn btn-danger" data-id={item.id} onClick={this.deleteEmployee}>Delete</button>
                                    </td>
                                </tr>
                            )
                        },this)}
                    </tbody>
                </table>
            </div>
        );
    }
};
export default AdminEmployees;
