import Service from '../components/Service';

export const Auth = {
  isAuthenticated: false,
  userId: false,
//   name: false,
  token: false,
  ted: 0,
  lastProductsPage: false,
  lang: '',
  languages: ['sr', 'en'],

  setLang(lang){
    if(lang){
     this.lang = lang;
    }else{
        this.lang = 'rs';
    }
  },

    initLang(){
        let lang = '';

        if(window.location.pathname.slice(0, 6).match("/admin")){
          return lang;
        }
        console.log('pn', window.location.pathname.split('/'));

        let routeLang = window.location.pathname.split('/')[1]
        if(this.languages.includes(routeLang)){
            localStorage.setItem('psofting_lang', routeLang);
        }
        lang = localStorage.getItem('psofting_lang');
        if(!lang){
            lang = '';
        }
        Auth.setLang(lang);
        return lang;
    },

    getLang(){
        return this.lang;
    },

  checkToken(){
      if(this.ted){
        let currentDate = Math.floor(Date.now() / 1000);
        
        console.log('date', currentDate, this.ted);
        if(currentDate > parseInt(this.ted)){
            console.log('ttt')
            this.signout();
            return false;
        }else{
            console.log('nnn');
        }
        return true;
      }
  },
  
  authenticate(password, cb, cb2) {

    Service.get('/users/login',{
        email: this.email,
        password: password
    },(res)=>{
        if(res.success){

        		this.isAuthenticated = true;
                this.userId = res.data.id;
                this.email = res.data.email;
                this.token = res.data.token;
                this.ted = res.data.ted;

                localStorage.setItem('ps-token', res.data.token);
                localStorage.setItem('ps-userId', res.data.id);
                localStorage.setItem('ps-email', this.email);
                localStorage.setItem('ps-ted', res.data.ted);

                cb(res);
        		
        	}else{

        		this.isAuthenticated = false;
                cb2(res.info);

        	}
    });    
  },

  setLastPage(page){
      this.lastProductsPage = page;
      localStorage.setItem('lastProductsPage', this.lastProductsPage);
  },

    logout(cb=null) {

        Service.get('/users/logout',{
            userId: this.userId,
            token: this.token
        },(res)=>{

            this.isAuthenticated = false;
            this.userId = false;
            // this.name = false;
            this.token = false;

            localStorage.removeItem('ps-token');
            localStorage.removeItem('ps-userId');
            localStorage.removeItem('ps-email');
            localStorage.removeItem('ps-ted');
            
            window.location.href = '/admin';

            if(cb){
                cb(res);
            }
        })
  },
 
  signout(cb) {
    this.logout();
    this.isAuthenticated = false;
    console.log(this.isAuthenticated, 'aaaa');
    localStorage.removeItem('ps-token');
    localStorage.removeItem('ps-userId');
    localStorage.removeItem('ps-email');
    localStorage.removeItem('ps-psted');
    setTimeout(cb, 100)
  }
}