import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {t} from '../components/ServiceLang';
import CustomSelect from './CustomSelect';
class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedContact: 0,
            contactOptions: [
                // {value: '0', label: t("contact")+' RUS'},
                {value: '0', label: t("contact")+' RS'},
                // {value: '1', label: t("contact")+' HR'}
            ]
        }

    }

    selectContact=(selectedOption)=>{
        this.setState({selectedContact: selectedOption});
    }

    render(){
        return(
            <div className="footer">
                <div className="container-padding">
                    <div className="footer-left">
                        <div className="footer-left-item">
                            <h2>Petrolsoft</h2>

                            <Link to="/o-nama">{t("aboutUs")}</Link>
                            <Link to="/reference">{t("reference")}</Link>
                            <Link to="/vesti">{t("news")}</Link>
                        </div>

                        <div className="footer-left-item">
                            <h2>Izdvojeno</h2>

                            <Link to="#">IT poslovna rešenja</Link>
                            <Link to="#">Autonomne benzinske stanice</Link>
                            <Link to="#">Pametne kartice</Link>
                            <Link to="#">{t("personalizedServices")}</Link>
                        </div>
                        <div className="footer-left-item">
                            <div className="select-country">
                                {/* <select onChange={this.selectContact} value={this.state.selectedContact}>
                                    <option value="0">{t("contact")} RUS</option>
                                    <option value="1">{t("contact")} RS</option>
                                    <option value="2">{t("contact")} HR</option>

                                </select> */}
                                <CustomSelect onChange={this.selectContact} defaultValue={this.state.selectedContact} options={this.state.contactOptions} class={'footer-country-select'}/>

                            {/* {this.state.selectedContact == 0 &&
                                <p><br />Konstantin Tedeev
                                    <br />Head of operations
                                    <br />+7 (903) — 797 08 70
                                    <br /><a href="mailto:konstantin.tedeev@petrolsofting.com" target="_top">konstantin.tedeev@petrolsofting.com</a>
                                </p>

                            } */}
                            {this.state.selectedContact == 1 &&
                                <p><br />Trgovačka 8,
                                    <br />10000 Zagreb,
                                    <br />+385 1 2409 157,
                                    <br />Fax: +385 1 2409 158, 
                                    <br /><a href="mailto:info@gia.hr" target="_top">info@gia.hr</a>
                                </p>
                            }
                            {this.state.selectedContact == 0 &&
                                <p><br/>Patrijarha Dimitrija 12i,
                                    <br/>11090 {t("belgrade")},
                                    <br/>+381 11 715 2552,
                                    <br/><a href="mailto:office@petrolsofting.com" target="_top">office@petrolsofting.com</a>
                                </p>
                            }
                            </div>
                        </div>

                        <div className="footer-left-item">
                            {/* <h2>{t("support")}</h2> */}
                        </div>

                        <div className="footer-left-item">
                            <h2>{t("certificates")}</h2>

                            <Link to="#">ISO 9001:2008</Link>
                            <Link to="#">ISO 27001:2015</Link>
                            <Link to="#">ISO 27001:2013</Link>
                        </div>
                    </div>
                    
                    <div className="footer-right">
                        <img src="/logo.png" alt="petrolsoft logo" />
                        <h2>HQ</h2>
                        <p>Patrijarha Dimitrija 12i</p>
                        <p>11090 {t("belgrade")}</p>
                        <p>+381 11 715 2552</p>
                        <p>office@petrolsofting.com</p>

                        <a href="https://www.facebook.com/petrolsofting/" target="_blank">Facebook</a><br />
                        <a href="https://www.instagram.com/petrolsoft_ing/" target="_blank">Instagram</a>
                    </div>
                </div>
                <p className="footer-copyright">Copyright &copy; 2021 Petrolsoft ING. All rights reserved.</p>
            </div>
        )
    }
}

export default Footer;