import React, { Component, Fragment } from 'react';
import {Products, ProductsEng} from '../components/HomeProductsJson';
import renderHTML from 'react-render-html';
import {Auth} from '../components/Auth';

class HomeProducts extends Component {

    constructor(props){
        super(props);
        this.state = {
          selectedProduct: 1,
          selectedProductItem: '',
          productImagePath: '', 
          prevTitle: '',
          prevSubtitle: '',
          nextTitle: '',
          nextSubtitle: '',
          products: [],
          lang: ''
        }
    }

    componentDidMount(){
        this.setProductImage(this.state.selectedProduct, this.state.selectedProductItem);

        if(Auth.getLang){
            this.setState({lang: Auth.getLang()},()=>{
                if(this.state.lang === 'sr'){
                    this.setState({products: Products},()=>{
                        this.setPrevNextProduct(this.state.selectedProduct, 'mount');
                    })
                }else if(this.state.lang === 'en'){
                    this.setState({products: ProductsEng},()=>{
                        this.setPrevNextProduct(this.state.selectedProduct, 'mount');
                    })
                }
            })
        }
    }

    selectProductItem=(id)=>{
        this.setState({selectedProductItem: id},()=>{
            this.setProductImage(this.state.selectedProduct, id);
            this.setPrevNextProduct(this.state.selectedProduct, 'event');
        })
    }

    setProductImage=(productId, itemId)=>{
        Products.map(function(item){
            if(itemId){
                if(item.id === productId){
                    item.items.map(function(items){
                        if(items.id === itemId){
                            this.setState({productImagePath: items.image});
                        }
                    },this)
                }
            }else{
                if(item.id === productId){
                    this.setState({productImagePath: item.image})
                }
            }
        },this)
    }

    setPrevNextProduct=(productId, type)=>{
        this.state.products.find((element) => {
            if(type === 'mount'){
                if(element.id === productId){
                    this.setState({prevTitle: element.title, prevSubtitle: element.subtitle})
                }    
            }
            if(element.id === productId - 1){
                this.setState({prevTitle: element.title, prevSubtitle: element.subtitle})
            }

            if(element.id === (productId + 1)){
                this.setState({nextTitle: element.title, nextSubtitle: element.subtitle})
            }
        })
    }

    setPrevProduct=()=>{
        let productId = this.state.selectedProduct;

        this.state.products.find((element) => {
            if(element.id === productId - 1){
                let id = parseInt(productId) - 1;
                this.setState({selectedProduct: id, selectedProductItem: ''},()=>{
                    this.setPrevNextProduct(this.state.selectedProduct, 'event');
                    this.setProductImage(this.state.selectedProduct, this.state.selectedProductItem);
                })
            }
        })
    }

    setNextProduct=()=>{
        let productId = this.state.selectedProduct;

        this.state.products.find((element) => {
            if(element.id === productId + 1){
                let id = productId + 1;
                this.setState({selectedProduct: id, selectedProductItem: ''},()=>{
                    this.setPrevNextProduct(this.state.selectedProduct, 'event');
                    this.setProductImage(this.state.selectedProduct, this.state.selectedProductItem);
                })
            }
        })
    }

    render(){
        return(
            <div className="home-products">
                <div className="container-padding">
                    {this.state.products.map((item, index)=>{
                        if(item.id === this.state.selectedProduct){
                            return(
                                <div className="home-middle-content">
                                    <div className="left">
                                        <h1>{item.title}</h1>
                                        <h3>{item.subtitle}</h3>
                                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                                        {/* {renderHTML(item.content)} */}
                                    </div>

                                    

                                    <div className="middle">
                                        <ul>
                                            {item.items.map((items)=>{
                                                return(
                                                    <li className="home-product-item">
                                                        <p onClick={()=>{this.selectProductItem(items.id)}} onMouseOver={()=>{this.selectProductItem(items.id)}}>{items.title}</p>
                                                    </li>
                                                )
                                            },this)}
                                        </ul>
                                        {item.items.map((items)=>{
                                            if(items.id === this.state.selectedProductItem){
                                                return(
                                                    <p>
                                                        {items.text}
                                                    </p>
                                                )
                                            }
                                        },this)}
                                        {/* <p>Plaćanje bezkontaktnim AVI prstenom ugrađenim na vozilu ili mobilnom aplikacijom </p> */}
                                    </div>

                                    <div className="right">
                                        {/* SLIKA */}
                                        {this.state.productImagePath &&
                                            <img src={this.state.productImagePath} />
                                        }
                                    </div>
                                    
                                </div>
                            )
                        }
                    },this)}
                </div>
              
                <div className="home-product-selector">
                    <div className="home-product-selector-left">
                        <img onClick={()=>{this.setPrevProduct()}} src="/arrow-long-left@3x.svg" />

                        <div>
                            <h4>{this.state.prevTitle}</h4>
                            <span>{this.state.prevSubtitle}</span>   
                        </div>
                    
                    </div>
                    
                    <div className="home-product-selector-right">
                    <img onClick={()=>{this.setNextProduct()}} src="/arrow-long-right@3x.svg" />

                        <div>
                            <h4>{this.state.nextTitle}</h4>
                            <span>{this.state.nextSubtitle}</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }

}

export default HomeProducts;