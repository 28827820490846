import {Auth} from '../components/Auth';

const lang = {
    serbia: ['Serbia', 'Srbija', ''],
    belgrade: ['Belgrade', 'Beograd', ''],
    bih: ['B&H', 'BiH', ''],
    bosniaAndHerzegovina: ['Bosnia and Herzegovina', 'Bosna i Hercegovina', ''],
    croatia: ['Croatia', 'Hrvatska', ''],
    russia: ['Russia', 'Rusija', ''],
    montenegro: ['Montenegro', 'Crna Gora', ''],
    bulgaria: ['Bulgaria', 'Bugarska', ''],
    romania: ['Romania', 'Rumunija', ''],
    thePowerOfTrueSupport: ['The power of true support', 'Snaga prave podrške', ''],
    support: ['Support', 'Podrška', ''], 
    home: ['Home', 'Početna', ''], 
    products: ['Products', 'Proizvodi', ''], 
    news: ['News', 'Vesti', ''], 
    news2: ['News', 'Novosti', ''], 
    career: ['Career', 'Karijera', ''], 
    application: ['Application', 'Prijava', ''],
    digitalTransformation: ['Digital transformation', 'Digitalna transformacija', ''],
    reference: ['References', 'Reference', ''],
    contact: ['Contact', 'Kontakt', ''],
    contacts: ['Contacts', 'Kontakti', ''],
    aboutUs: ['About us', 'O nama', ''],
    distributors: ['Distributors', 'Distributeri', ''],
    representativeOffice: ['Representative office', 'Predstavništva', ''],
    serviceCompany: ['Service company', 'Servisna kompanija', ''],
    itBusinessSolution: ['IT business solution', 'IT poslovna rešenja', ''],
    personalizedServices: ['Personalized services', 'Personalizovani servisi', ''],
    certificates: ['Certificates', 'Sertifikati', ''],
    homeSubtitle: ['Petrolsoft - More than 30 years with you', 'Petrolsoft - Više od 30 godina sa Vama', ''],
    homeTitle: ['IT solutions in the oil industry', 'IT rešenja u naftnoj industriji', ''],
    homeText1: ['The real challenges require great solutions.', 'Pravi izazovi zahtevaju sjajna rešenja.', ''],
    homeText2: ['We present fiscalized and localized <b> .Orfej </b> POS BOS modules.', 'Predstavljamo fiskalizovane i lokalizovane <b>.Orfej</b> POS BOS module.', ''],
    homeLearnMoreButton: ['Learn more about the Orfej system', 'Saznaj više o Orfej sistemu', ''],
    allNews: ['All news', 'Sve vesti', ''],
    archive: ['Archive', 'Arhiva', ''],
    yourTime: ['Your time', 'Vaše vreme', ''],
    BELGRADE: ['BELGRADE', 'BEOGRAD', ''],
    MOSCOW: ['MOSCOW', 'MOSKVA', ''],
    BUCHAREST: ['BUCHAREST', 'BUKUREŠT', ''],
    informations: ['Informations', 'Informacije', ''],
    address: ['Address', 'Adresa', ''],
    city: ['City', 'Grad', ''],
    phone: ['Phone', 'Telefon', ''],
    january: ['JANUARY', 'JANUAR', ''],
    february: ['FEBRUARY', 'FEBRUAR', ''],
    march: ['MARCH', 'MART', ''],
    april: ['APRIL', 'APRIL', ''],
    may: ['MAY', 'MAJ', ''],
    june: ['JUNE', 'JUN', ''],
    july: ['JULY', 'JUL', ''],
    august: ['AUGUST', 'AVGUST', ''],
    september: ['SEPTEMBER', 'SEPTEMBAR', ''],
    october: ['OCTOBER', 'OKTOBAR', ''],
    november: ['NOVEMBER', 'NOVEMBAR', ''],
    december: ['DECEMBER', 'DECEMBAR', ''],
    characteristics: ['Characteristics', 'Karakteristike', ''],
    cookies: ['Do you accept cookies?', `Radi unapređenja poslovanja i interaktivnog iskustva - da li prihvatate da ovaj sajt koristi 'kolačiće' (cookies)?`, ''],
    accept: ['Accept', 'Prihvatam', ''],
    fullName: ['Full name', 'Ime i prezime', ''],
    message: ['Message', 'Poruka', ''],
    uploadCV: ['Upload CV', 'Dodaj CV', ''],
    nameEmpty: ['* enter your full name', '* unesite ime i prezime', ''],
    emailEmpty: ['* please enter your email', '* unesite email adresu', ''],
    fileEmpty: ['* please upload your CV', '* dodajte svoj CV', ''],
    applicationMessage: ['You successfully apply for ','Uspešno ste se prijavili na oglas ',''],
    noRecaptcha: ['* confirm that you are not a robot.', '* potvrdite da niste robot.', ''],
    noOpenPositions: ['There are no open positions', 'Trenutno nema otvorenih pozicija', ''],
    ourTeam: ['Our team', 'Naš tim', ''],
}

let languageCodes = {
    en: 0,
    sr: 1,
    // hr: 2
}

export function t(key, debug=false) {
    if(debug){
        // console.log("Store", Store, Store.lang, Lang[key], Lang[key][languageCodes[Store.lang]]);
    }
    return lang[key][languageCodes[Auth.getLang()]];
}