import React, { Component } from 'react';
import Service from '../components/Service';

class AdminApplications extends Component {
    constructor(){
        super();
        this.state={
            applications: [],
            positions: []
        }
    }

    componentDidMount(){
        this.getApplications();
        this.getPositions();
    }

    getApplications=()=>{
        Service.get('/adm/getApplications',{
        },(res)=>{
            if(res.success){
                this.setState({applications: res.applications})
            }
        }); 
    }

    getPositions=()=>{
        Service.get('/adm/getPositions',{},(res)=>{
            if(res.success){
                this.setState({
                    positions: res.positions
                })
            }
        });
    }

    getPositionName=(id)=>{
        let positionName = '';

        this.state.positions.map((item)=>{
            if(item.id === id){
                positionName = item.name
            }
        })
        return positionName;

    }

    seeApplication=(event)=>{
        let id = event.target.getAttribute('data-id');

        window.location.href= '/admin/application-single/' + id;
    }

    setApplicationStatus=(e)=>{
        let applicationId = e.target.getAttribute('data-id');

        Service.get("/adm/setApplicationStatus",{
            applicationId,
            status: e.target.value
        }, (res) => {
            if(res.success){
                this.getApplications();
            }
        });
    }

    render() {
        return (     
            <div>
                <h1>Applications</h1>
                
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Position</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.applications.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td scope="row">{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{this.getPositionName(item.position_id)}</td>
                                    <td>
                                        <select value={item.status} data-id={item.id} onChange={this.setApplicationStatus}>
                                            <option value="0" >Pending</option>
                                            <option value="1" >Seen</option>
                                            <option value="2" >Approved</option>
                                            <option value="3" >Rejected</option>
                                        </select>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-secondary" data-id={item.id} onClick={this.seeApplication}>See more</button>

                                    </td>
                                </tr>
                            )
                        },this)}
                    </tbody>
                </table>
            </div>
        );
    }
};
export default AdminApplications;
