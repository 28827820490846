import React, { Component, Fragment } from 'react';

class CustomSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayOptions: false,
            options: [],
            value: ''
        }
    }  

    componentDidMount(){
        if(this.props.defaultValueString){
            const defaultValue = this.props.options.findIndex(item => item.value === this.props.defaultValue)

            this.setState({options: this.props.options, value: defaultValue})
        }else{
            this.setState({options: this.props.options, value: this.props.defaultValue})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue !== this.state.defaultValue) {
            if(this.props.defaultValueString){
                const defaultValue = this.props.options.findIndex(item => item.value === nextProps.defaultValue)

                this.setState({ value: defaultValue});
            }else{
                this.setState({ value: nextProps.defaultValue});
            }
        }
      }

    displayOptions = () => {
        this.setState({displayOptions: !this.state.displayOptions})
    }

    selectOption = (option) => {
        this.setState({displayOptions: false}, ()=>{
            this.props.onChange(option);
        })
    }

    render() { 
        return (
            <div className="custom-select">
                {this.state.options.length > 0 &&
                    <div onClick={this.displayOptions} className={this.props.class ? this.props.class : null}><span>{this.state.options[this.state.value].label}</span> <img src={this.props.arrow === 'gray' ? "/petrolsoft-selector-arrow-gray.svg" : "/petrolsoft-selector-arrow-blue.svg"}/></div>
                }
                
                {this.state.displayOptions &&
                    <ul>
                        {this.state.options.map((item, index)=>{
                            return(
                                <li onClick={()=>{this.selectOption(item.value)}}>{item.label}</li>
                            )
                        })}
                    </ul>
                }
            </div>
        );
    }

}

export default CustomSelect;