import React, { Component, Fragment} from 'react';
import Service from '../components/Service';
import {NotificationManager} from 'react-notifications';

class AdminContactSingle extends Component {
    constructor(){
        super();
        this.state={
            id: '',
            category: '',
            countryId: '',
            country: '',
            name: '',
            address: '',
            city: '',
            phone: '',
            fax: '',
            email: '',
            title: ''
        }
    }

    componentDidMount(){
        if(this.props.id){
            this.getContact();
        }
    }

    getContact=()=>{
        Service.get('/adm/getContacts',{
            id: this.props.id
        },(res)=>{
            if(res.success){
                this.setState({
                    id: res.contacts[0].id,
                    name: res.contacts[0].name,
                    category: res.contacts[0].category,
                    country: res.contacts[0].country,
                    countryId: res.contacts[0].country_id,
                    address: res.contacts[0].address,
                    city: res.contacts[0].city,
                    phone: res.contacts[0].phone,
                    fax: res.contacts[0].fax,
                    email: res.contacts[0].email,
                    title: res.contacts[0].title
                })
            }
        });
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    updateContact=()=>{
        if(!window.confirm('Are you sure that you want to update this contact?')){
            return;
        }

        Service.get('/adm/updateContact',{
            id: this.props.id,
            name: this.state.name,
            category: this.state.category,
            country: this.state.country,
            countryId: this.state.countryId,
            address: this.state.address,
            city: this.state.city,
            phone: this.state.phone,
            fax: this.state.fax,
            email: this.state.email,
            title: this.state.title,
        },(res)=>{
            if(res.success){
                this.getContact();
                NotificationManager.success("You have successfully update contact")
            }
        }); 
    }

    setCategory=(event)=>{
        this.setState({category: event.target.value})
    }

    setCountry=(event)=>{
        let index = event.nativeEvent.target.selectedIndex;
        let country = event.nativeEvent.target[index].text;

        this.setState({countryId: event.target.value, country})
    }

    render() {
        return (     
            <div>
                <h1>{this.state.name}</h1>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="category" className="label-select-language">Category</label>
                    <select id="category" value={this.state.category} onChange={this.setCategory}>
                        <option value="representativeOffice" >Representative Office</option>
                        <option value="distributor" >Distributor</option>
                        <option value="serviceCompany" >Service company</option>
                    </select> 
                </div>

                <div className="form-group">
                    <label htmlFor="country" className="label-select-language">Country</label>
                    <select id="country" value={this.state.countryId} onChange={this.setCountry}>
                        <option value="0">serbia</option>
                        <option value="1" >bih</option>
                        <option value="2" >croatia</option>
                        <option value="3" >romania</option>
                        <option value="4" >bulgaria</option>
                        {/* <option value="5" >russia</option> */}
                        <option value="5" >montenegro</option>
                    </select> 
                </div>

                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" className="form-control" id="address" value={this.state.address} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input type="text" className="form-control" id="city" value={this.state.city} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input type="text" className="form-control" id="phone" value={this.state.phone} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="fax">Fax</label>
                    <input type="text" className="form-control" id="fax" value={this.state.fax} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="text" className="form-control" id="email" value={this.state.email} onChange={this.handleInput}/>
                </div>

                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" value={this.state.title} onChange={this.handleInput}/>
                </div>
                
                <button type="submit" className="btn btn-primary" onClick={this.updateContact}>Submit</button>
            </div>
        );
    }
};
export default AdminContactSingle;
