import React, { Component, Fragment } from 'react';
import {BrowserRouter as Router, Route, Link, Redirect, Read} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {Auth} from '../components/Auth';
import {t} from '../components/ServiceLang';
import CustomSelect from './CustomSelect';

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            lang: Auth.getLang(),
            phoneNumbers: [
                '+381 ( 11 ) — 715 2552',
                '+387 ( 51 ) — 962 950',
                '+385 1 560 1100',
                // '+7 903 797 08 70'
            ],
            selectCountry: '0',
            countryOptions: [
                {value: '0', label: t("serbia")},
                {value: '1', label: t("bih")},
                {value: '2', label: t("croatia")},
                // {value: '3', label: t("russia")}
            ],
            langOptions: [
                {value: 'sr', label: 'sr'},
                {value: 'en', label: 'en'},
                // {value: 'hr', label: 'hr'}
            ]
        }
    }

    setLang=(selectedOption)=>{
        this.setState({lang: selectedOption}, ()=>{
            localStorage.setItem('psofting_lang', selectedOption);
            window.location = '/'+selectedOption;
            //console.log(Auth.getLang());
        })
    }

    selectCountry=(selectedOption)=>{
        this.setState({selectCountry: selectedOption});
    }

    toggleMenu=()=>{
        let el = document.getElementById("menu");
        if(window.screen.width <= 720){
            if(el.style.display === "none" || !el.style.display){
                el.style.display = "block";
            } else {
                el.style.display = "none";
            }
        }
    }

    render(){
        return(
            <div id="header">
                <div className="container-padding">
                    <div className="header-top">
                        <div className="left">
                            <p className="phone">{this.state.phoneNumbers[this.state.selectCountry]}</p>
                            <div className="select-country" value={this.state.selectedCountry} onChange={this.selectCountry}>
                                {/* <select>
                                    <option value="0">{t("serbia")}</option>
                                    <option value="1">{t("bih")}</option>
                                    <option value="2">{t("croatia")}</option>
                                    <option value="3">{t("russia")}</option>
                                </select> */}
                                <CustomSelect onChange={this.selectCountry} defaultValue={this.state.selectCountry} options={this.state.countryOptions} class={'header-country-select'} arrow={'gray'}/>

                            </div>
                        </div>
                        <div className="middle">
                            <div className="header-mobile-icons">
                                <Link to="/kontakt"><img  src="/icon-tel2x.png" /></Link>
                                <Link to="/podrska"><img  src="/icon-support2x.png" /></Link>
                            </div>
                            <img className="logo" src="/logo.png" alt="petrolsoft logo" />
                            <p>{t("thePowerOfTrueSupport")}</p>
                        </div>
                        <div className="right">
                            {/* <p>SR</p> */}
                                {/* <select value={this.state.lang} onChange={this.setLang}>
                                    <option value='sr'>sr</option>
                                    <option value='en'>en</option>

                                </select> */}
                                <CustomSelect onChange={this.setLang} defaultValue={this.state.lang} options={this.state.langOptions} defaultValueString={true} class={'header-language-select'}/>

                                {window.screen.width > 720 &&
                                    <Fragment>
                                        <Link to="/podrska">{t("support")}</Link>
                                    </Fragment>
                                }
                            
                                <span className="hamburger" onClick={this.toggleMenu}><img  src="/icon-menu.svg" /></span>
                        </div>
                    </div>
                </div>
                
                <div id="menu" className="header-bottom" >
                    <ul onClick={this.toggleMenu}>
                        <li className={this.props.location.pathname === '/' ? 'header-active': null}><Link to="/">{t("home")}</Link></li>
                        <li className={this.props.location.pathname === '/o-nama' ? 'header-active': null}><Link to="/o-nama">{t("aboutUs")}</Link></li>
                        <li className={this.props.location.pathname === '/reference' ? 'header-active': null}><Link to="/reference">{t("reference")}</Link></li>
                        {/* <li className={this.props.location.pathname === '/digitalna-transofrmacija' ? 'header-active': null}><Link to="/digitalna-transformacija">{t("digitalTransformation")}</Link></li> */}
                        <li className={this.props.location.pathname === '/proizvodi' ? 'header-active': null}><Link to="/proizvodi">{t("products")}</Link></li>
                        <li className={this.props.location.pathname === '/vesti' ? 'header-active': null}><Link to="/vesti">{t("news")}</Link></li>
                        <li className={this.props.location.pathname === '/karijera' ? 'header-active': null}><Link to="/karijera">{t("career")}</Link></li>
                        <li className={this.props.location.pathname === '/kontakt' ? 'header-active': null}><Link to="/kontakt">{t("contact")}</Link></li>
                        {window.screen.width <= 720 &&
                            <Fragment>
                                <li><Link to="/podrska">{t("support")}</Link></li>
                            </Fragment>
                        }
                    </ul>
                </div>
            </div>
        )
    }

}

export default withRouter(Header);